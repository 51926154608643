<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="title"
		size="xl"
		centered
		cancel-variant="light"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="container-layout">
			<tax-form
				ref="form"
				:action="action"
				:display-add-btn="false"
				:item="tax"
				@finance-tax-form:waiting="onTaxFormLoaded"
				@finance-tax-form:close-modal="onTaxFormForceClosed"
				@finance-tax-form:reload="onTaxFormReloaded"
			/>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="md"
					variant="custom-outline-gray"
					style="margin-top: 5px"
					class="w-138-px mr-3"
					:disabled="watingSubmitForm"
					@click="emitEventClose"
					block
				>
					{{ FormMSG(1, 'Close') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="watingSubmitForm" @click="dispatchEvent" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import TaxForm from '@/components/Finance/TaxForm';
import _ from 'lodash';
import { ACTION } from '@/shared/constants';

export default {
	name: 'FinanceFlagFormModal',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: true,
			default: ''
		},
		action: {
			type: String,
			required: true,
			default: ''
		},
		item: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	components: {
		TaxForm
	},
	data() {
		return {
			watingSubmitForm: false,
			tax: null
		};
	},
	methods: {
		emitEventClose() {
			this.$emit('finance-tax-form-modal:closed');
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['form'].$refs['submit'].click();
		},
		onTaxFormLoaded(payload) {
			this.watingSubmitForm = payload;
		},
		onTaxFormForceClosed(payload) {
			if (!_.isNil(payload)) {
				if (_.isString(payload)) {
					this.createToastForMobile(this.FormMSG(3, 'Success'), payload, '', 'success');
					this.$emit('finance-tax-form-modal:refreshed');
				}
				this.$emit('finance-tax-form-modal:closed');
			}
		},
		onTaxFormReloaded(payload) {
			this.$emit('finance-tax-form-modal:reloaded', payload);
		}
	},
	watch: {
		item: {
			handler(value) {
				if (this.action === ACTION.UPDATE) {
					if (!_.isNil(value)) {
						this.tax = _.cloneDeep(value);
					}
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped></style>
