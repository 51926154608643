<template>
	<div class="animated fadeIn" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<b-form @submit.prevent="submitForm">
			<b-row class="mb-2">
				<b-col>
					<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(1, 'General') }}
						</legend>
						<b-row :class="`${$screen.width <= 576 ? '' : 'py-2'}`">
							<b-col sm="2" v-if="action === ACTION.UPDATE && flag.customExportName && flag.customExportName !== ''">
								<b-form-group :label="FormMSG(25, 'ExportId')">
									<b-form-input type="text" v-model="flag.customExportName" disabled />
								</b-form-group>
							</b-col>
							<b-col :sm="`${action === ACTION.UPDATE && flag.customExportName && flag.customExportName !== '' ? 4 : 7}`">
								<b-form-group :label="FormMSG(4, 'Category')">
									<b-form-input
										type="text"
										autocomplete="off"
										v-model="flag.name"
										:class="{
											'is-invalid': $v.flag.name.$error
										}"
									/>
									<div v-if="$v.flag.name.$error && $screen.width <= 576" class="invalid-feedback">
										{{ FormMSG(5, 'Please, category name is required') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="2">
								<b-form-group :label="FormMSG(6, 'Abbreviation')">
									<b-form-input
										type="text"
										autocomplete="off"
										v-model="flag.short"
										:class="{
											'is-invalid': $v.flag.short.$error
										}"
									/>
									<div v-if="$v.flag.short.$error && $screen.width <= 576" class="invalid-feedback">
										{{ FormMSG(7, 'Please, short name is required') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="2">
								<b-form-group :label="FormMSG(12, 'Green Flag')">
									<b-form-checkbox
										size="lg"
										v-model="flag.greenFlag"
										:class="`${$screen.width <= 992 ? '' : 'mt-1'}`"
										:style="`${$screen.width <= 992 ? 'margin-top: -4px;' : ''}`"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="2">
								<b-form-group :label="FormMSG(26, 'Mandatory')">
									<b-form-checkbox
										size="lg"
										v-model="flag.mandatoryFlag"
										:class="`${$screen.width <= 992 ? '' : 'mt-1'}`"
										:style="`${$screen.width <= 992 ? 'margin-top: -4px;' : ''}`"
									/>
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row :class="`${$screen.width <= 992 ? 'mt-2' : 'mt-2'}`">
				<b-col>
					<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(2, 'Information') }}
						</legend>
						<b-row :class="`${$screen.width <= 576 ? 'pb-2' : 'py-2'}`">
							<b-col>
								<b-form-group :label="FormMSG(8, 'Description')">
									<b-form-textarea v-model="flag.description" type="textarea" rows="2" autocomplete="off" />
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row :class="`${$screen.width <= 992 ? 'mt-2 pb-4' : 'mt-2 pb-2'}`">
				<b-col>
					<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(3, 'Flags') }}
						</legend>
						<b-row class="mt-2">
							<b-col>
								<card-list-builder
									v-if="$screen.width < 992 && flag.projectFlagItem.length > 0"
									:use-new-version="true"
									:toggle-mode="true"
									:items="flag.projectFlagItem"
									:fields="flagFields"
									:validators="validatorPatterns"
									:class="`${flag.projectFlagItem.length > 0 ? 'pb-3' : ''}`"
								>
									<template slot="disabled" slot-scope="{ item, index }" v-if="item.id > 0">
										<b-form-checkbox
											v-model="item.disabled"
											:title="checkboxTitle(item)"
											v-b-tooltip.focus.right
											size="lg"
											@change="handleCheckboxChanged($event, 'disabled', index)"
										/>
									</template>
									<template slot="name" slot-scope="{ item, error, index, isLineValid }">
										<b-form-input
											size="sm"
											type="text"
											autocomplete="off"
											v-model="item.name"
											:class="{
												'is-invalid': error
											}"
											@keyup="handleInputChanged(item.name, 'name', index, isLineValid)"
										/>
									</template>
									<template slot="short" slot-scope="{ item, error, index, isLineValid }">
										<b-form-input
											size="sm"
											type="text"
											autocomplete="off"
											v-model="item.short"
											:class="{
												'is-invalid': error
											}"
											@keyup="handleInputChanged(item.short, 'short', index, isLineValid)"
										/>
									</template>
									<template slot="actions" slot-scope="data">
										<b-button
											variant="danger"
											size="sm"
											class="ml-2 btn bg-transparent border-0"
											@click="
												onLineTableRemoved({
													index: data.index,
													id: data.item.id
												})
											"
										>
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</template>
								</card-list-builder>
								<div class="text-center pb-3 mb-2" style="font-size: 1rem" v-if="$screen.width < 992 && flag.projectFlagItem.length === 0">
									{{ FormMSG(14, 'Please, add a new flag') }}
								</div>
								<b-table-simple small :borderless="true" v-if="$screen.width >= 992 && flag.projectFlagItem.length > 0">
									<b-thead style="color: rgba(6, 38, 62, 0.84)">
										<b-tr style="line-height: 8px">
											<b-th v-if="isDataExist && flag.projectFlagItem.length > 0"></b-th>
											<b-th>{{ FormMSG(9, 'Name') }}</b-th>
											<b-th>{{ FormMSG(10, 'Abbreviation') }}</b-th>
											<b-th></b-th>
										</b-tr>
									</b-thead>
									<b-tbody class="text-center">
										<item-form
											v-for="(item, i) in flag.projectFlagItem"
											:ref="generateTextId(`item-form-${i}`)"
											:key="i"
											:index="i"
											:item="item"
											:existDataAlreadySubmitted="existDataAlreadySubmitted"
											@finance-table-flag-item-form:updated="onLineTableChanged"
											@finance-table-flag-item-form:removed="onLineTableRemoved"
										/>
									</b-tbody>
								</b-table-simple>
								<div class="text-center pb-3" style="font-size: 1rem" v-if="$screen.width >= 992 && flag.projectFlagItem.length === 0">
									{{ FormMSG(14, 'Please, add a new flag') }}
								</div>
							</b-col>
						</b-row>
						<b-row class="mb-2 pb-3">
							<div class="d-flex justify-content-end" style="width: 93.5%">
								<b-button
									size="sm"
									variant="outline-primary"
									class="w-138-px d-flex justify-content-center align-items-center"
									:disabled="$v.$invalid"
									@click="addNewFlag"
								>
									<component :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
									<div class="px-2" style="margin-bottom: -2px">
										{{ FormMSG(11, 'Add new flag') }}
									</div>
								</b-button>
							</div>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					class="d-flex justify-content-center mt-2"
					sm="4"
					md="4"
					lg="4"
					xl="4"
					offset-sm="5"
					offset-md="5"
					offset-lg="5"
					offset-xl="5"
					:style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`"
				>
					<b-button ref="submit" type="submit" size="md" variant="outline-primary" class="w-138-px" block>{{ FormMSG(13, 'Save') }}</b-button>
				</b-col>
			</b-row>
		</b-form>
		<b-modal
			header-class="header-class-modal-doc-package"
			header-bg-variant="warning"
			:title="this.FormMSG(18, 'Warning')"
			class="modal-warning"
			v-model="warningMissingFlagItemModal"
			@ok="warningMissingFlagItemModal = false"
			ok-variant="warning"
			ok-only
		>
			<div style="font-size: 1rem">
				{{ FormMSG(19, 'Please fill the field(s) required (coloring red) in flags section or remove one or all of them.') }}
			</div>
		</b-modal>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { addUpdateProjectFlag, addUpdateProjectFlagItem, deleteProjectFlagItem } from '@/cruds/flag.crud';
import ItemForm from '@/components/Finance/TableFlagItemForm';
import { generateSecureId } from '@/shared/utils';
import { ACTION, FLAG_TYPE } from '@/shared/constants';

export default {
	name: 'FinanceFlagForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		ItemForm
	},
	props: {
		action: {
			type: String,
			required: true,
			default: ACTION.ADD // ADD, UPDATE
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: true
		},
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		isDataExist: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		flagFields() {
			return [
				{
					key: 'disabled',
					label: this.FormMSG(22, 'Disable ?'),
					class: 'text-center',
					isSlot: true
				},
				{
					key: 'name',
					label: this.FormMSG(9, 'Name'),
					class: 'text-center',
					isSlot: true,
					required: true
				},
				{
					key: 'short',
					label: this.FormMSG(10, 'Abbreviation'),
					class: 'text-center',
					isSlot: true,
					required: true
				}
			];
		}
	},
	data() {
		return {
			flag: {
				id: 0,
				name: '',
				short: '',
				decription: '',
				greenFlag: false,
				projectFlagItem: []
			},
			isSubmitted: false,
			// flags: [],
			isValidFlags: [],
			warningMissingFlagItemModal: false,
			existDataAlreadySubmitted: false,
			validatorPatterns: {
				name: {
					required,
					min: minLength(3)
				},
				short: {
					required
				}
			},
			addUpdFlagItems: [],
			ACTION: ACTION
		};
	},
	created() {
		this.$v.$touch();
	},
	methods: {
		async submitForm(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid || !this.validFlags()) {
				if (!this.validFlags()) {
					this.warningMissingFlagItemModal = true;
				}
				return;
			}

			if (this.action === ACTION.ADD) {
				await this.manageAction(0);
			} else if (this.action === ACTION.UPDATE) {
				await this.manageAction(+this.flag.id);
			}
		},
		async manageAction(id = 0) {
			this.$emit('finance-flag-form:waiting', true);
			await addUpdateProjectFlag(+id, {
				name: this.flag.name,
				short: this.flag.short,
				description: this.flag.description,
				greenFlag: this.flag.greenFlag,
				mandatoryFlag: this.flag.mandatoryFlag,
				type: FLAG_TYPE.DEFAULT_FLAG
			})
				.then(async (record) => {
					for (const flagItem of this.addUpdFlagItems) {
						let item = {
							name: flagItem.name,
							short: flagItem.short
						};

						if (+flagItem.id === 0) {
							item = {
								flagId: record.flagId,
								...item
							};
						} else {
							item = {
								disabled: flagItem.disabled,
								...item
							};
						}

						await addUpdateProjectFlagItem(+flagItem.id, item).catch((error) => {
							console.error({ error });
						});
					}
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					let message = '';
					if (this.action === ACTION.ADD) {
						message =
							this.flag.projectFlagItem.length === 0
								? this.FormMSG(16, 'Flag category created successfully, but you need to create flags later.')
								: this.FormMSG(17, 'Flag category and flags created successfully.');
					} else if (this.action === ACTION.UPDATE) {
						message =
							this.flag.projectFlagItem.length === 0
								? this.FormMSG(20, 'Flag category updated successfully, but you need to create flags later.')
								: this.FormMSG(21, 'Flag category and flags updated successfully.');
					}
					this.$emit('finance-flag-form:waiting', false);
					this.$emit('finance-flag-form:close-modal', message);
				});
		},
		onLineTableChanged({ index, item, isLineValid }) {
			if (this.addUpdFlagItems.length === 0) {
				this.addUpdFlagItems.push({
					...item,
					index
				});
			} else {
				const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
				if (idx === -1) {
					this.addUpdFlagItems.push({
						...item,
						index
					});
				} else {
					this.addUpdFlagItems[idx] = Object.assign(this.addUpdFlagItems[idx], item);
				}
			}

			this.isValidFlags[index] = isLineValid;
		},
		onLineTableRemoved({ index, id }) {
			if (id === 0) {
				this.flag.projectFlagItem.splice(index, 1);
				const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
				if (idx !== -1) {
					this.addUpdFlagItems.splice(idx, 1);
				}
				this.isValidFlags.splice(index, 1);
			} else {
				const action = async () => {
					await deleteProjectFlagItem(id)
						.then((result) => {
							if (result) {
								this.flag.projectFlagItem.splice(index, 1);
								const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
								if (idx !== -1) {
									this.addUpdFlagItems.splice(idx, 1);
								}
								this.isValidFlags.splice(index, 1);

								this.$emit('finance-flag-form:reload', true);
							}
						})
						.catch((e) => {
							console.error({ e });
						});
				};
				this.confirmModal(action, this.FormMSG(15, 'Are you sure to remove this flag ?'), 'danger');
			}

			if (this.flag.projectFlagItem.length === 0) {
				this.existDataAlreadySubmitted = false;
			} else {
				const existTrueData = this.flag.projectFlagItem.filter((flagItem) => +flagItem.id > 0);
				this.existDataAlreadySubmitted = existTrueData.length > 0;
			}
		},
		addNewFlag() {
			const flagItem = {
				id: 0,
				flagId: 0,
				name: '',
				short: ''
			};

			this.flag.projectFlagItem.push(flagItem);
			this.isValidFlags.push(false);
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		validFlags() {
			if (this.isValidFlags.length === 0) return true;
			let isValidFlags = this.isValidFlags.join(',');

			return !/false/.test(isValidFlags);
		},
		checkboxTitle(item) {
			return item.disabled === false ? this.FormMSG(23, 'Disable') : this.FormMSG(24, 'Enable');
		},
		handleInputChanged(payload, fieldName, index, isLineValid) {
			if (this.addUpdFlagItems.length === 0) {
				let flagItem = this.flag.projectFlagItem[index];
				flagItem[fieldName] = payload;
				this.addUpdFlagItems.push({
					...flagItem,
					index
				});
			} else {
				const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
				if (idx === -1) {
					let flagItem = this.flag.projectFlagItem[index];
					flagItem[fieldName] = payload;
					this.addUpdFlagItems.push({
						...flagItem,
						index
					});
				} else {
					this.addUpdFlagItems[idx][fieldName] = payload;
				}
			}

			this.isValidFlags[index] = isLineValid;
		},
		handleCheckboxChanged(payload, fieldName, index) {
			if (this.addUpdFlagItems.length === 0) {
				let flagItem = this.tax.projectFlagItem[index];
				flagItem[fieldName] = payload;

				this.addUpdFlagItems.push({
					...flagItem,
					index
				});
			} else {
				const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
				if (idx === -1) {
					let flagItem = this.tax.projectFlagItem[index];

					flagItem[fieldName] = payload;

					this.addUpdFlagItems.push({
						...flagItem,
						index
					});
				} else {
					this.addUpdFlagItems[idx][fieldName] = payload;
				}
			}
		}
	},
	watch: {
		item: {
			handler(value) {
				if (this.action === ACTION.UPDATE) {
					if (!_.isNil(value)) {
						this.flag = _.cloneDeep(value);
						if (this.flag.projectFlagItem.length > 0) {
							this.flag.projectFlagItem.forEach(() => {
								this.isValidFlags.push(true);
								this.existDataAlreadySubmitted = true;
							});
						}
					}
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			flag: {
				name: {
					required,
					min: minLength(3)
				},
				short: {
					required
				}
			}
		};
	}
};
</script>
<style lang="scss" scoped></style>
