<template>
	<div class="card">
		<DataTable stripedRows showGridlines :value="perDiems" @cell-edit-complete="onCellEditComplete" editMode="cell">
			<Column field="name" header="Name" sortable>
				<template #editor="slotProps">
					<InputText v-model="slotProps.data[slotProps.column.field]" autofocus />
				</template>
			</Column>
			<Column field="short" header="Abbreviation" sortable>
				<template #editor="slotProps">
					<InputText v-model="slotProps.data[slotProps.column.field]" autofocus />
				</template>
			</Column>
			<Column field="amount" header="Amount" sortable>
				<template #editor="slotProps">
					<InputText v-model="slotProps.data[slotProps.column.field]" autofocus />
				</template>
				<template #body="{ data }">
					{{ displayCurrency(data.amount) }}
				</template>
			</Column>
			<Column field="category" bodyClass="w-300" header="Category" sortable>
				<template #editor="slotProps">
					<treeselect
						v-model="slotProps.data[slotProps.column.field]"
						:multiple="false"
						:clearable="false"
						:options="categories"
						:disable-branch-nodes="true"
						:placeholder="FormMSG(569, 'Select a category')"
					>
						<div slot="option-label" slot-scope="{ node }">
							<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
						</div>
					</treeselect>
					<!-- <InputText v-model="slotProps.data[slotProps.column.field]" autofocus /> -->
				</template>
				<template #body="{ data }">
					<div>
						<treeselect
							v-model="data.category"
							:multiple="false"
							:clearable="false"
							disabled
							:options="categories"
							:disable-branch-nodes="true"
							:placeholder="FormMSG(569, 'Select a category')"
						>
							<div slot="option-label" slot-scope="{ node }">
								<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
							</div>
						</treeselect>
					</div>
				</template>
			</Column>
			<Column field="payCode" header="Pay code" sortable>
				<template #editor="slotProps">
					<payroll-code style-type="select" @payroll-code:input="handlePayCodeChange" :value="slotProps.data[slotProps.column.field]" />
				</template>
				<template #body="{ data }">
					<payroll-code style-type="select" :value="data.payCode" :disabled="true" />
				</template>
			</Column>
			<Column field="color" header="Color">
				<template #editor="slotProps">
					<div class="text-center">
						<color-picker v-model="slotProps.data[slotProps.column.field]" autofocus />
					</div>
				</template>
				<template #body="slotProps">
					<div class="text-center">
						<color-picker disabled v-model="slotProps.data.color" format="hex" />
					</div>
				</template>
			</Column>
			<Column header="Planning overview">
				<template #body="{ data }">
					<div class="text-center">
						<Chip :label="data.short" :style="{ background: '#' + data.color, color: rendTagTxtColor(data.color) }"></Chip>
					</div>
				</template>
			</Column>
			<Column field="bookmark" header="Bookmark" sortable>
				<template #body="slotProps">
					<div class="text-center">
						<i
							class="pi pi-star-fill cursor-pointer"
							@click="handleBookmarkChange(slotProps)"
							v-if="slotProps.data.bookmark"
							style="font-size: 25px; color: #edbd05"
						></i>
						<i class="pi pi-star cursor-pointer" @click="handleBookmarkChange(slotProps)" v-else style="font-size: 25px"></i>
					</div>
				</template>
			</Column>
			<Column header="Remove">
				<template #body="{ data }">
					<div class="text-center">
						<Trash2 :size="25" class="cursor-pointer" @click="handleDeleteDiem(data.id)" color="tomato" />
					</div>
				</template>
			</Column>
		</DataTable>
		<div class="w-100">
			<b-button
				style="float: right"
				@click="handleAddNewFlag"
				size="sm"
				variant="outline-primary"
				class="d-flex justify-content-center align-items-center"
			>
				<component :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
				<div class="px-2" style="margin-bottom: -2px">
					{{ FormMSG(13, 'Add new custom per diem') }}
				</div>
			</b-button>
		</div>
	</div>
</template>
<script>
import Column from 'primevue/column/Column';
import DataTable from 'primevue/datatable/DataTable';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import InputText from 'primevue/inputtext';
import { getProjectFlags, addUpdateProjectFlagItem, deleteProjectFlagItem } from '@/cruds/flag.crud';
import { Trash2 } from 'lucide-vue';
import { rendCurrency } from '~helpers';
import ColorPicker from 'primevue/colorpicker';
import Chip from 'primevue/chip';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { removeAttributeTree } from '@/shared/utils';
import PayrollCode from '@/components/Spaiectacle/PayrollCode';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';

export default {
	name: 'TimeManagement',
	components: { DataTable, Column, ColorPicker, Treeselect, PayrollCode, Chip, Trash2, InputText },
	mixins: [LanguageMessages, GlobalMixin],
	data() {
		return {
			perDiems: [],
			flagId: 0,
			categories: [],
			newPayCode: 0
		};
	},
	created() {
		this.reloadData();
		this.getCategories();
	},
	methods: {
		displayCurrency(value) {
			return rendCurrency(value);
		},
		async handleBookmarkChange(event) {
			let { data, field } = event;
			data[field] = !data[field];
			let id = data.id;
			let item = data;

			await addUpdateProjectFlagItem(id, item);
		},
		handleDeleteDiem(id) {
			const action = async () => {
				await deleteProjectFlagItem(id)
					.then((result) => {
						if (result) {
							this.reloadData();
						}
					})
					.catch((e) => {
						console.error({ e });
					});
			};
			this.confirmModal(action, this.FormMSG(15, 'Are you sure to remove this custom per diem ?'), 'danger');
		},
		async handleAddNewFlag() {
			const flagItem = {
				id: 0,
				flagId: this.flagId,
				name: 'Default',
				short: 'DF',
				amount: 0,
				allowanceRate: 0,
				category: 0,
				payCode: 0,
				color: '',
				bookmark: false
			};
			await addUpdateProjectFlagItem(flagItem.id, flagItem);
			this.reloadData();
		},
		handlePayCodeChange(payload) {
			this.newPayCode = payload;
		},
		async onCellEditComplete(event) {
			let { data, newValue, field } = event;
			if (field == 'payCode') {
				data[field] = parseInt(this.newPayCode);
			} else if (field == 'amount') {
				data[field] = parseFloat(newValue);
			} else {
				data[field] = newValue;
			}
			let id = data.id;
			let item = data;

			await addUpdateProjectFlagItem(id, item);
		},
		async getCategories() {
			this.categories = [];
			await getBudgetHierarchicalCategoryJson(-2, false, null, -1, false).then((result) => {
				this.categories = removeAttributeTree(result, 'children', null);
			});
			this.categories = [...this.categories, { id: 0, label: this.FormMSG(121212, 'Select a category') }];
		},
		rendTagTxtColor(color) {
			const normalizedColor = color.replace(/^([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => r + r + g + g + b + b);

			// Extract the RGB components from the normalized hexadecimal color
			const red = parseInt(normalizedColor.slice(1, 3), 16);
			const green = parseInt(normalizedColor.slice(3, 5), 16);
			const blue = parseInt(normalizedColor.slice(5, 7), 16);

			// Calculate luminance using the formula: Y = 0.299*R + 0.587*G + 0.114*B
			const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

			// If the luminance is less than 0.5, it's a dark color, so use white font; otherwise, use black font
			return luminance < 0.5 ? 'white' : 'black';
		},
		async reloadData() {
			await getProjectFlags().then((res) => {
				if (res) {
					this.flagId = res.customPerDiemFlags[0].flagId;
					this.perDiems = res.customPerDiemFlags[0].projectFlagItem;
				}
			});
		}
	}
};
</script>
<style>
.w-300 {
	width: 300px !important;
}
</style>
