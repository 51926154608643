var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.flag
    ? _c(
        "b-tr",
        { staticClass: "animated fadeIn" },
        [
          _vm.flag.id > 0
            ? _c(
                "b-td",
                { staticStyle: { "vertical-align": "middle", width: "2%" } },
                [
                  _c("b-form-checkbox", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left",
                        modifiers: { hover: true, left: true },
                      },
                    ],
                    attrs: { title: _vm.checkboxTitle, size: "lg" },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckboxChanged($event, "disabled")
                      },
                    },
                    model: {
                      value: _vm.flag.disabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.flag, "disabled", $$v)
                      },
                      expression: "flag.disabled",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.flag.id === 0 ? _c("b-td") : _vm._e(),
          _c(
            "b-td",
            { staticStyle: { "vertical-align": "middle", width: "5%" } },
            [
              _c("b-form-input", {
                staticStyle: { "text-align": "center" },
                attrs: { type: "text", autocomplete: "off", disabled: "" },
                model: {
                  value: _vm.flagRef,
                  callback: function ($$v) {
                    _vm.flagRef = $$v
                  },
                  expression: "flagRef",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            { staticStyle: { width: "15%" } },
            [
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.flag.name.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("name")
                  },
                },
                model: {
                  value: _vm.flag.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.flag, "name", $$v)
                  },
                  expression: "flag.name",
                },
              }),
            ],
            1
          ),
          !_vm.forAllowances
            ? _c(
                "b-td",
                { staticStyle: { width: "10%" } },
                [
                  _c("b-form-input", {
                    class: {
                      "is-invalid": _vm.$v.flag.short.$error,
                    },
                    attrs: { type: "text", autocomplete: "off" },
                    on: {
                      keyup: function ($event) {
                        return _vm.handleInputChanged("short")
                      },
                    },
                    model: {
                      value: _vm.flag.short,
                      callback: function ($$v) {
                        _vm.$set(_vm.flag, "short", $$v)
                      },
                      expression: "flag.short",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-td",
            [
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.flag.amount.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  change: function ($event) {
                    return _vm.handleInputChanged("amount")
                  },
                },
                model: {
                  value: _vm.flag.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.flag, "amount", $$v)
                  },
                  expression: "flag.amount",
                },
              }),
            ],
            1
          ),
          _vm.forAllowances
            ? _c(
                "b-td",
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.periodicalOptions },
                    on: {
                      input: function ($event) {
                        return _vm.handleInputChanged("allowanceRate")
                      },
                    },
                    model: {
                      value: _vm.flag.allowanceRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.flag, "allowanceRate", $$v)
                      },
                      expression: "flag.allowanceRate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-td",
            { staticStyle: { width: "20%" } },
            [
              _c("treeselect", {
                class: {
                  "is-invalid": _vm.$v.flag.category.$error,
                },
                attrs: {
                  multiple: false,
                  clearable: false,
                  options: _vm.categories,
                  "disable-branch-nodes": true,
                  placeholder: _vm.FormMSG(569, "Select a category"),
                },
                on: {
                  input: function ($event) {
                    return _vm.handleInputChanged("category")
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option-label",
                      fn: function ({ node }) {
                        return _c("div", {}, [
                          _c(
                            "div",
                            {
                              staticClass: "treeselect-label",
                              attrs: { title: node.label },
                            },
                            [_vm._v(_vm._s(node.label))]
                          ),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  2657349975
                ),
                model: {
                  value: _vm.flag.category,
                  callback: function ($$v) {
                    _vm.$set(_vm.flag, "category", $$v)
                  },
                  expression: "flag.category",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            { staticStyle: { width: "18%" } },
            [
              _c("payroll-code", {
                class: {
                  "is-invalid": _vm.$v.flag.payCode.$error,
                },
                attrs: { "style-type": "select", value: _vm.flag.payCode },
                on: {
                  "payroll-code:input": _vm.handlePayCodeChange,
                  "payroll-code:reset": _vm.handleResetPayCode,
                },
              }),
            ],
            1
          ),
          !_vm.forAllowances
            ? _c(
                "b-td",
                [
                  _c("color-picker", {
                    attrs: { format: "hex" },
                    on: {
                      input: function ($event) {
                        return _vm.handleInputChanged("color")
                      },
                    },
                    model: {
                      value: _vm.flag.color,
                      callback: function ($$v) {
                        _vm.$set(_vm.flag, "color", $$v)
                      },
                      expression: "flag.color",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.forAllowances
            ? _c("b-td", [
                _vm.flag.bookmark
                  ? _c("i", {
                      staticClass: "pi pi-star-fill cursor-pointer",
                      staticStyle: { "font-size": "25px", color: "#edbd05" },
                      on: {
                        click: function ($event) {
                          return _vm.handleInputChanged("bookmark")
                        },
                      },
                    })
                  : _c("i", {
                      staticClass: "pi pi-star cursor-pointer",
                      staticStyle: { "font-size": "25px" },
                      on: {
                        click: function ($event) {
                          return _vm.handleInputChanged("bookmark")
                        },
                      },
                    }),
              ])
            : _vm._e(),
          _c(
            "b-td",
            { staticStyle: { "vertical-align": "middle", width: "2%" } },
            [
              _c(_vm.getLucideIcon("MinusCircle"), {
                tag: "component",
                staticClass: "cursor-pointer mr-1",
                attrs: { color: "#EA4E2C", size: 18, "stroke-width": 3 },
                on: { click: _vm.removeItem },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }