var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    {
      staticClass: "animated fadeIn",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _c(
        "b-col",
        { attrs: { sm: "3" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(1, "Name") } },
            [
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.company.name.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("name")
                  },
                },
                model: {
                  value: _vm.company.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "name", $$v)
                  },
                  expression: "company.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { sm: "6" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(2, "Address") } },
                    [
                      _c("v-address", {
                        attrs: {
                          params: { title: _vm.FormMSG(3, "Address") },
                          "address-id": _vm.company.addressId,
                          "is-submitted": true,
                          error: _vm.$v.company.addressId.$error,
                        },
                        on: {
                          change: _vm.handleDynamicAddressChange,
                          delete: _vm.handleDynamicAddressDelete,
                          "address-maplink-change":
                            _vm.handleAddressMapLinkChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "5" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "bic" } }, [
                          _vm._v(
                            _vm._s(
                              this.FormMSG(30, "Company Registration Number")
                            )
                          ),
                        ]),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: this.FormMSG(
                            31,
                            "Enter your Company Registration Number"
                          ),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged(
                              "companyRegistrationNumber"
                            )
                          },
                        },
                        model: {
                          value: _vm.company.companyRegistrationNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.company,
                              "companyRegistrationNumber",
                              $$v
                            )
                          },
                          expression: "company.companyRegistrationNumber",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "7" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "bic" } }, [
                          _vm._v(
                            _vm._s(this.FormMSG(32, "Tax Registration Number"))
                          ),
                        ]),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: this.FormMSG(
                            33,
                            "Enter your Tax Registration Number"
                          ),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged(
                              "taxRegistrationNumber"
                            )
                          },
                        },
                        model: {
                          value: _vm.company.taxRegistrationNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "taxRegistrationNumber", $$v)
                          },
                          expression: "company.taxRegistrationNumber",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "5" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "bic" } }, [
                          _vm._v(_vm._s(this.FormMSG(34, "General Phone"))),
                        ]),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: this.FormMSG(
                            35,
                            "Enter your General Phone"
                          ),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("phone")
                          },
                        },
                        model: {
                          value: _vm.company.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "phone", $$v)
                          },
                          expression: "company.phone",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "7" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "bic" } }, [
                          _vm._v(_vm._s(this.FormMSG(36, "General Email"))),
                        ]),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: this.FormMSG(
                            37,
                            "Enter your General Email"
                          ),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("email")
                          },
                        },
                        model: {
                          value: _vm.company.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "email", $$v)
                          },
                          expression: "company.email",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "5" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "bic" } }, [
                          _vm._v(_vm._s(this.FormMSG(38, "Accounts phone"))),
                        ]),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: this.FormMSG(
                            39,
                            "Enter your Accounts phone"
                          ),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("accountPhone")
                          },
                        },
                        model: {
                          value: _vm.company.accountPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "accountPhone", $$v)
                          },
                          expression: "company.accountPhone",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "7" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "bic" } }, [
                          _vm._v(_vm._s(this.FormMSG(40, "Accounts email"))),
                        ]),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: this.FormMSG(
                            41,
                            "Enter your Accounts email"
                          ),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("accountEmail")
                          },
                        },
                        model: {
                          value: _vm.company.accountEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "accountEmail", $$v)
                          },
                          expression: "company.accountEmail",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "5" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "bic" } }, [
                          _vm._v(
                            _vm._s(this.FormMSG(42, "Po starting number"))
                          ),
                        ]),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: this.FormMSG(
                            43,
                            "Enter Po starting number"
                          ),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("lastPoNumber")
                          },
                        },
                        model: {
                          value: _vm.company.lastPoNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "lastPoNumber", $$v)
                          },
                          expression: "company.lastPoNumber",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("b-col", { attrs: { sm: "7" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-col", { attrs: { sm: "3" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }