<template>
	<errorContainer :error="erreur">
		<transition name="slide">
			<div>
				<div class="animated fadeIn">
					<b-row>
						<div class="w-100 p-1 container-layout">
							<div class="d-flex flex-colmumn card w-100 py-4">
								<b-form class="d-flex flex-column w-100 p-1">
									<div class="card-collapsible">
										<div
											class="d-flex flex-row cursor-pointer header-card-collapsible align-items-center responsive-mobile"
											@click="handleClickHeader('personnal-data')"
										>
											<div class="text-mobile title w-100">
												<b-icon icon="person-check-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">
													{{ FormMSG(12654, 'Personal data') }}
												</span>
											</div>
											<div class="d-flex justify-content-end w-100">
												<component
													v-if="!configAccordion['personnal-data'].isVisible"
													:is="getLucideIcon('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['personnal-data'].isVisible"
													:is="getLucideIcon('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['personnal-data'].isVisible">
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-80 flex-column gap-1">
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(121, 'Personal number') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('PersonalNumber')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.usePersonalNumber"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(122, 'Email') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			disabled
																			@click="handleUseChange('Email')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useEmail"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox disabled v-model="projectFieldOption.mandatoryEmail">
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(123, 'Phone') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Phone')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.usePhone"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.usePhone"
																				v-model="projectFieldOption.mandatoryPhone"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(124, 'First Name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			disabled
																			@click="handleUseChange('FirstName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useFirstName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<custom-disabled-checkbox
																				isDisable
																				:value="projectFieldOption.mandatoryFirstName"
																				:label="FormMSG(1115511, 'Mandatory')"
																			/>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(125, 'Last Name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			disabled
																			@click="handleUseChange('LastName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useLastName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<custom-disabled-checkbox
																				isDisable
																				:value="projectFieldOption.mandatoryLastName"
																				:label="FormMSG(1115511, 'Mandatory')"
																			/>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(189, 'Maiden name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('MaidenName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useMaidenName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useMaidenName"
																				v-model="projectFieldOption.mandatoryMaidenName"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(169, 'Color') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Color')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useColor"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useColor"
																				v-model="projectFieldOption.mandatoryColor"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="d-flex">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(126, 'Nationality') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Nationality')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useNationality"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useNationality"
																				v-model="projectFieldOption.mandatoryNationality"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(127, 'Language') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Language')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useLanguage"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useLanguage"
																				v-model="projectFieldOption.mandatoryLanguage"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(131, 'Gender') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Gender')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useGender"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useGender"
																				v-model="projectFieldOption.mandatoryGender"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(200, 'Title') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Title')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useTitle"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useTitle"
																				v-model="projectFieldOption.mandatoryTitle"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="d-flex flex-row flex-wrap">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(128, 'Birthdate') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Birthdate')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useBirthdate"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useBirthdate"
																				v-model="projectFieldOption.mandatoryBirthdate"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(129, 'Place of birth') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('PlaceOfBirth')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.usePlaceOfBirth"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.usePlaceOfBirth"
																				v-model="projectFieldOption.mandatoryPlaceOfBirth"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(130, 'City of birth ZIP') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('CityOfBirthZip')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useCityOfBirthZip"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useCityOfBirthZip"
																				v-model="projectFieldOption.mandatoryCityOfBirthZip"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(201, 'Country of birth') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('CountryOfBirth')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useCountryOfBirth"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useCountryOfBirth"
																				v-model="projectFieldOption.mandatoryCountryOfBirth"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
													<div class="d-flex flex-wrap align-items-center w-20 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div>
																		<b-avatar class="avatar"></b-avatar>
																	</div>
																	<div class="d-flex flex-row justify-content-center" style="gap: 5px">
																		<div class="d-flex flex-row">
																			<b-button
																				@click="handleUseChange('ProfilePicture')"
																				variant="primary"
																				size="sm"
																				class="btn bg-transparent border-0"
																			>
																				<component
																					v-if="projectFieldOption.useProfilePicture"
																					:is="getLucideIcon(ICONS.EYE.name)"
																					:color="ICONS.EYE.color"
																					:size="20"
																				/>
																				<component
																					v-else
																					:is="getLucideIcon(ICONS.EYE_OFF.name)"
																					:color="ICONS.EYE_OFF.color"
																					:size="20"
																				/>
																			</b-button>
																		</div>
																		<div class="d-flex align-items-center justify-content-center">
																			<div>
																				<b-form-checkbox
																					:disabled="!projectFieldOption.useProfilePicture"
																					v-model="projectFieldOption.mandatoryProfilePicture"
																				>
																					<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																				</b-form-checkbox>
																			</div>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
									<!-- Identification-->
									<div class="card-collapsible mt-3">
										<div
											class="d-flex flex-row cursor-pointer header-card-collapsible align-items-center responsive-mobile"
											@click="handleClickHeader('identification')"
										>
											<div class="text-mobile title w-100">
												<b-icon icon="person-lines-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">
													{{ FormMSG(106, 'Identification') }}
												</span>
											</div>
											<div class="d-flex justify-content-end w-100">
												<component
													v-if="!configAccordion['identification'].isVisible"
													:is="getLucideIcon('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['identification'].isVisible"
													:is="getLucideIcon('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['identification'].isVisible">
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(256, 'Identification Numbers') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap">
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(133, 'National Registration Number') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('NationalRegistrationNumber')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useNationalRegistrationNumber"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useNationalRegistrationNumber"
																				v-model="projectFieldOption.mandatoryNationalRegistrationNumber"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(134, 'Company Number') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('CompanyNumber')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useCompanyNumber"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useCompanyNumber"
																				v-model="projectFieldOption.mandatoryCompanyNumber"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(300, '"Congés spectacles" ID') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('CongesSpectacleId')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useCongesSpectacleId"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useCongesSpectacleId"
																				v-model="projectFieldOption.mandatoryCongesSpectacleId"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(142, 'Signature picture') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SignaturePicture')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSignaturePicture"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSignaturePicture"
																				v-model="projectFieldOption.mandatorySignaturePicture"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap">
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(135, 'ID Name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('IdName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useIdName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useIdName"
																				v-model="projectFieldOption.mandatoryIdName"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(136, 'ID Number') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('IdNumber')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useIdNumber"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useIdNumber"
																				v-model="projectFieldOption.mandatoryIdNumber"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(137, 'ID picture - Front') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('IdPictureFront')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useIdPictureFront"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useIdPictureFront"
																				v-model="projectFieldOption.mandatoryIdPictureFront"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(138, 'ID picture - Back') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('IdPictureBack')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useIdPictureBack"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useIdPictureBack"
																				v-model="projectFieldOption.mandatoryIdPictureBack"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap">
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(333, 'Driver license') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('DriverLicense')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useDriverLicense"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useDriverLicense"
																				v-model="projectFieldOption.mandatoryDriverLicense"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(139, 'Driver license number') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('DriverLicenseNumber')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useDriverLicenseNumber"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useDriverLicenseNumber"
																				v-model="projectFieldOption.mandatoryDriverLicenseNumber"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(140, 'Driver license picture - Front') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('DriverLicensePictureFront')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useDriverLicensePictureFront"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useDriverLicensePictureFront"
																				v-model="projectFieldOption.mandatoryDriverLicensePictureFront"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(141, 'Driver license picture - Back') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('DriverLicensePictureBack')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useDriverLicensePictureBack"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useDriverLicensePictureBack"
																				v-model="projectFieldOption.mandatoryDriverLicensePictureBack"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
									<!-- Finance -->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('finance')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('Wallet')" color="rgba(6, 38, 62, 0.84)" :size="22" />
												<span class="ml-3">{{ FormMSG(1244, 'Finance') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['finance'].isVisible"
													:is="getLucideIcon('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['finance'].isVisible"
													:is="getLucideIcon('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['finance'].isVisible">
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(1235654, 'Bank information') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(143, 'IBAN') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Iban')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useIban"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useIban"
																				v-model="projectFieldOption.mandatoryIban"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(144, 'BIC') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Bic')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useBic"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useBic"
																				v-model="projectFieldOption.mandatoryBic"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(3323, 'Bank account owner name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('BankAccountOwnerName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useBankAccountOwnerName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useBankAccountOwnerName"
																				v-model="projectFieldOption.mandatoryBankAccountOwnerName"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(556, 'Bank detail document') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('BankDetailDocument')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useBankDetailDocument"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useBankDetailDocument"
																				v-model="projectFieldOption.mandatoryBankDetailDocument"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<!-- Second bank information -->
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(101, 'Expense Reimbursement Bank Information') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(102, 'IBAN') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SecondIban')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSecondIban"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSecondIban"
																				v-model="projectFieldOption.mandatorySecondIban"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(103, 'BIC') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SecondBic')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSecondBic"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSecondBic"
																				v-model="projectFieldOption.mandatorySecondBic"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(104, 'Bank account owner name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SecondBankAccountOwnerName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSecondBankAccountOwnerName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSecondBankAccountOwnerName"
																				v-model="projectFieldOption.mandatorySecondBankAccountOwnerName"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(105, 'Bank detail document') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SecondBankDetailDocument')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSecondBankDetailDocument"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSecondBankDetailDocument"
																				v-model="projectFieldOption.mandatorySecondBankDetailDocument"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<!-- Second bank information -->
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(164, 'Taxes') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(164, 'Taxes') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Taxes')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useTaxes"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useTaxes"
																				v-model="projectFieldOption.mandatoryTaxes"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(334, 'Tax Declaration Country') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('TaxDeclarationCountry')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useTaxDeclarationCountry"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useTaxDeclarationCountry"
																				v-model="projectFieldOption.mandatoryTaxDeclarationCountry"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
									<!-- Address-->
									<div class="card-collapsible mt-3">
										<div
											class="d-flex flex-row cursor-pointer header-card-collapsible align-items-center responsive-mobile"
											@click="handleClickHeader('address')"
										>
											<div class="text-mobile title w-100">
												<b-icon icon="geo-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3"> Address </span>
											</div>
											<div class="d-flex justify-content-end w-100">
												<component
													v-if="!configAccordion['address'].isVisible"
													:is="getLucideIcon('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['address'].isVisible"
													:is="getLucideIcon('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['address'].isVisible">
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(145, 'Country') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Country')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useCountry"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useCountry"
																				v-model="projectFieldOption.mandatoryCountry"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(146, 'Internet search') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('InternetResearch')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useInternetResearch"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(147, 'Street') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Street')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useStreet"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useStreet"
																				v-model="projectFieldOption.mandatoryStreet"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(148, 'Number') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Number')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useNumber"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useNumber"
																				v-model="projectFieldOption.mandatoryNumber"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(149, 'Box') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Box')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useBox"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useBox"
																				v-model="projectFieldOption.mandatoryBox"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(150, 'Zip Code') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('ZipCode')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useZipCode"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useZipCode"
																				v-model="projectFieldOption.mandatoryZipCode"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(151, 'City') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('City')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useCity"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useCity"
																				v-model="projectFieldOption.mandatoryCity"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(152, 'State/Region') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('StateRegion')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useStateRegion"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useStateRegion"
																				v-model="projectFieldOption.mandatoryStateRegion"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
									<!-- health -->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('health')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('HeartPulse')" color="rgba(6, 38, 62, 0.84)" :size="22" />
												<span class="ml-3">{{ FormMSG(1252, 'Health') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['health'].isVisible"
													:is="getLucideIcon('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['health'].isVisible"
													:is="getLucideIcon('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['health'].isVisible">
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(132, 'Social Security Number') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('SocialSecurityNumber')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useSocialSecurityNumber"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useSocialSecurityNumber"
																	v-model="projectFieldOption.mandatorySocialSecurityNumber"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(600, 'Social security card') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('SocialSecurityCard')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useSocialSecurityCard"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useSocialSecurityCard"
																	v-model="projectFieldOption.mandatorySocialSecurityCard"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(601, 'Insurance certificate') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('InsuranceCertificate')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useInsuranceCertificate"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useInsuranceCertificate"
																	v-model="projectFieldOption.mandatoryInsuranceCertificate"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(605, 'Social security center') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('SocialSecurityCenter')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useSocialSecurityCenter"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useSocialSecurityCenter"
																	v-model="projectFieldOption.mandatorySocialSecurityCenter"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(606, 'Social security center address') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('SocialSecurityCenterAddress')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useSocialSecurityCenterAddress"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useSocialSecurityCenterAddress"
																	v-model="projectFieldOption.mandatorySocialSecurityCenterAddress"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(607, 'Last medical visit date') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('LastMedicalVisitDate')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useLastMedicalVisitDate"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useLastMedicalVisitDate"
																	v-model="projectFieldOption.mandatoryLastMedicalVisitDate"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(608, 'Medical visit validity') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('MedicalVisitValidity')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useMedicalVisitValidity"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useMedicalVisitValidity"
																	v-model="projectFieldOption.mandatoryMedicalVisitValidity"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(609, 'Medical visit document') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('MedicalVisitDocument')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useMedicalVisitDocument"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useMedicalVisitDocument"
																	v-model="projectFieldOption.mandatoryMedicalVisitDocument"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(166, 'Worker with a disability') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('WorkerWithADisability')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useWorkerWithADisability"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<!-- <div>
															<b-form-checkbox
																:disabled="!projectFieldOption.useWorkerWithADisability"
																v-model="projectFieldOption.mandatoryWorkerWithADisability"
															>
																<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
															</b-form-checkbox>
														</div> -->
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(710, 'Disability notification date') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('DisabilityNotificationDate')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useDisabilityNotificationDate"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useDisabilityNotificationDate"
																	v-model="projectFieldOption.mandatoryDisabilityNotificationDate"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(711, 'Type') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('Type')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useType"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useType"
																	v-model="projectFieldOption.mandatoryType"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(712, 'Status') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('Status')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useStatus"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useStatus"
																	v-model="projectFieldOption.mandatoryStatus"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(715, 'Invalidity') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('Invalidity')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useInvalidity"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useInvalidity"
																	v-model="projectFieldOption.mandatoryInvalidity"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(713, 'RQTH') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('Rqth')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useRqth"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useRqth"
																	v-model="projectFieldOption.mandatoryRqth"
																>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(714, 'Invalidity Percentage') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('InvalidityPercentage')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useInvalidityPercentage"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useInvalidityPercentage"
																	v-model="projectFieldOption.mandatoryInvalidityPercentage"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(167, 'Have Allergies?') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('HaveAllergies')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useHaveAllergies"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<!-- <div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useHaveAllergies"
																	v-model="projectFieldOption.mandatoryHaveAllergies"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div> -->
														</div>
													</div>
												</b-col>
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(168, 'Allergies details') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('AllergiesDetails')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useAllergiesDetails"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useAllergiesDetails"
																	v-model="projectFieldOption.mandatoryAllergiesDetails"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
										</b-collapse>
									</div>
									<!-- Family -->
									<div class="card-collapsible mt-3">
										<div
											class="d-flex flex-row cursor-pointer header-card-collapsible align-items-center responsive-mobile"
											@click="handleClickHeader('family')"
										>
											<div class="text-mobile title w-100">
												<b-icon icon="people-fill" style="color: rgba(6, 38, 62, 0.84)" />
												<span class="ml-3"> Family </span>
											</div>
											<div class="d-flex justify-content-end w-100">
												<component
													v-if="!configAccordion['family'].isVisible"
													:is="getLucideIcon('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['family'].isVisible"
													:is="getLucideIcon('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['family'].isVisible">
											<div class="text-mobile-sub-title sub-title">Family composition</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(153, 'Civil status') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('CivilStatus')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useCivilStatus"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useCivilStatus"
																				v-model="projectFieldOption.mandatoryCivilStatus"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(154, 'Spouse first name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SpouseFirstName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSpouseFirstName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSpouseFirstName"
																				v-model="projectFieldOption.mandatorySpouseFirstName"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(155, 'Spouse last name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SpouseLastName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSpouseLastName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSpouseLastName"
																				v-model="projectFieldOption.mandatorySpouseLastName"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(156, 'Spouse birthdate') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SpouseBirthdate')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSpouseBirthdate"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSpouseBirthdate"
																				v-model="projectFieldOption.mandatorySpouseBirthdate"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(157, 'Spouse has salary?') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SpouseHasSalary')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSpouseHasSalary"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<!-- <div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSpouseHasSalary"
																				v-model="projectFieldOption.mandatorySpouseHasSalary"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div> -->
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(1570, 'Spouse profession') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SpouseProfession')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSpouseProfession"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSpouseProfession"
																				v-model="projectFieldOption.mandatorySpouseProfession"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(158, 'Number of dependent children') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('NumberOfDependentChildren')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useNumberOfDependentChildren"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useNumberOfDependentChildren"
																				v-model="projectFieldOption.mandatoryNumberOfDependentChildren"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(159, 'Number of disabled children') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('NumberOfDisabledChildren')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useNumberOfDisabledChildren"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useNumberOfDisabledChildren"
																				v-model="projectFieldOption.mandatoryNumberOfDisabledChildren"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(160, 'Number of dependent persons') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('NumberOfDependentPerson')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useNumberOfDependentPerson"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useNumberOfDependentPerson"
																				v-model="projectFieldOption.mandatoryNumberOfDependentPerson"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(161, 'Number of disabled persons') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('NumberOfDisabledPerson')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useNumberOfDisabledPerson"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useNumberOfDisabledPerson"
																				v-model="projectFieldOption.mandatoryNumberOfDisabledPerson"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="divider"></div>
											<div class="text-mobile-sub-title sub-title">Emergency contact</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(162, 'Contact name') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('ContactName')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useContactName"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useContactName"
																				v-model="projectFieldOption.mandatoryContactName"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(163, 'Contact phone number') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('ContactPhoneNumber')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useContactPhoneNumber"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useContactPhoneNumber"
																				v-model="projectFieldOption.mandatoryContactPhoneNumber"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
									<!-- Vehicle Presonal -->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('personal-vehicle')"
										>
											<div class="title w-90">
												<!-- <b-icon icon="car-front-fill" style="color: rgba(6, 38, 62, 0.84)" /> -->
												<!-- <i class="bi bi-car-front"></i> -->
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="1em"
														height="1.15em"
														fill="rgba(6, 38, 62, 0.84)"
														viewBox="0 0 16 16"
													>
														<path
															d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"
														/>
													</svg>
												</span>
												<span class="ml-3">{{ FormMSG(1226, 'Personal vehicle') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['personal-vehicle'].isVisible"
													:is="getLucideIcon('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['personal-vehicle'].isVisible"
													:is="getLucideIcon('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['personal-vehicle'].isVisible">
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(706, 'Name') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('VehicleName')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useVehicleName"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useVehicleName"
																	v-model="projectFieldOption.mandatoryVehicleName"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div> </b-col
												><b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(7806, 'Brand') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('VehicleBrand')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useVehicleBrand"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useVehicleBrand"
																	v-model="projectFieldOption.mandatoryVehicleBrand"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div> </b-col
												><b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(705, 'Model') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('VehicleModel')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useVehicleModel"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useVehicleModel"
																	v-model="projectFieldOption.mandatoryVehicleModel"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div> </b-col
												><b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(704, 'Number plate') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('VehiclePlateNumber')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useVehiclePlateNumber"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useVehiclePlateNumber"
																	v-model="projectFieldOption.mandatoryVehiclePlateNumber"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(703, 'Transportation mean') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('VehicleTransportationMean')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useVehicleTransportationMean"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useVehicleTransportationMean"
																	v-model="projectFieldOption.mandatoryVehicleTransportationMean"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div> </b-col
												><b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(702, 'Transportation mean detail') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																disabled
																@click="handleUseChange('VehicleTransportationMeanDetail')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useVehicleTransportationMeanDetail"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox disabled v-model="projectFieldOption.mandatoryVehicleTransportationMeanDetail">
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div> </b-col
												><b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(700, 'Kg CO2 per Km') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('VehicleKgCoTwo')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useVehicleKgCoTwo"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useVehicleKgCoTwo"
																	v-model="projectFieldOption.mandatoryVehicleKgCoTwo"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
											<div class="d-flex">
												<b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(707, 'Power') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('Power')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.usePower"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.usePower"
																	v-model="projectFieldOption.mandatoryPower"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div> </b-col
												><b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(708, 'Gray card front picture') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('GrayCardFrontPicture')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useGrayCardFrontPicture"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	v-model="projectFieldOption.mandatoryGrayCardFrontPicture"
																	:disabled="!projectFieldOption.useGrayCardFrontPicture"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div> </b-col
												><b-col :cols="3" class="d-flex flex-column">
													<div class="d-flex flex-column align-items-start p-2">
														<div class="d-flex flex-row align-items-start">
															<label class="text-mobile" for="reference">
																{{ FormMSG(709, 'Gray card back picture') }}
															</label>
														</div>
														<div class="d-flex flex-row flex align-items-center">
															<b-button
																@click="handleUseChange('GrayCardBackPicture')"
																variant="primary"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	v-if="projectFieldOption.useGrayCardBackPicture"
																	:is="getLucideIcon(ICONS.EYE.name)"
																	:color="ICONS.EYE.color"
																	:size="20"
																/>
																<component
																	v-else
																	:is="getLucideIcon(ICONS.EYE_OFF.name)"
																	:color="ICONS.EYE_OFF.color"
																	:size="20"
																/>
															</b-button>
															<div>
																<b-form-checkbox
																	:disabled="!projectFieldOption.useGrayCardBackPicture"
																	v-model="projectFieldOption.mandatoryGrayCardBackPicture"
																>
																	<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																</b-form-checkbox>
															</div>
														</div>
													</div>
												</b-col>
											</div>
										</b-collapse>
									</div>
									<!-- Other -->
									<div class="card-collapsible mt-3">
										<div
											class="d-flex flex-row align-items-center cursor-pointer header-card-collapsible responsive-mobile"
											@click="handleClickHeader('other')"
										>
											<div class="text-mobile title w-100">
												<b-icon icon="list" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3"> Other </span>
											</div>
											<div class="d-flex justify-content-end w-100">
												<component
													v-if="!configAccordion['other'].isVisible"
													:is="getLucideIcon('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['other'].isVisible"
													:is="getLucideIcon('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['other'].isVisible">
											<div class="divider"></div>
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(15688, 'Privacy options') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('showMyEmailInCrewList')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.showMyEmailInCrewList"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div class="d-flex align-items-center justify-content-start">
																			<label class="text-mobile pt-2">{{
																				FormMSG(168484, 'Show my email in crew list')
																			}}</label>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('showMyPhoneInCrewList')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.showMyPhoneInCrewList"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div class="d-flex align-items-center justify-content-center">
																			<label class="text-mobile pt-2">{{
																				FormMSG(165, 'Show my phone in crew list')
																			}}</label>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="divider"></div>
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(599, 'Loyalty cards') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(520, 'SNCF loyalty card') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SncfLoyaltyCard')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSncfLoyaltyCard"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSncfLoyaltyCard"
																				v-model="projectFieldOption.mandatorySncfLoyaltyCard"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(521, 'SNCF discount card') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('SncfDiscountCard')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSncfDiscountCard"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSncfDiscountCard"
																				v-model="projectFieldOption.mandatorySncfDiscountCard"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(522, 'Flying blue card ') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('FlyingBlueCard')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useFlyingBlueCard"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useFlyingBlueCard"
																				v-model="projectFieldOption.mandatoryFlyingBlueCard"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(523, 'Other airline card') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('OtherAirlineCard')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useOtherAirlineCard"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useOtherAirlineCard"
																				v-model="projectFieldOption.mandatoryOtherAirlineCard"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="divider"></div>
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(530, 'Foreign worker') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(531, 'Residence permit number') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('ResidencePermitNumber')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useResidencePermitNumber"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useResidencePermitNumber"
																				v-model="projectFieldOption.mandatoryResidencePermitNumber"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(532, 'Residence permit validity end') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('ResidenceValidityEnd')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useResidenceValidityEnd"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useResidenceValidityEnd"
																				v-model="projectFieldOption.mandatoryResidenceValidityEnd"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(533, 'Resident permit') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('ResidencePermit')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useResidencePermit"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useResidencePermit"
																				v-model="projectFieldOption.mandatoryResidencePermit"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(534, 'Work card') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('WorkCard')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useWorkCard"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useWorkCard"
																				v-model="projectFieldOption.mandatoryWorkCard"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="divider"></div>
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(598, 'Miscellanous') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(535, 'Pseudonym') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Pseudonym')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.usePseudonym"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.usePseudonym"
																				v-model="projectFieldOption.mandatoryPseudonym"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="5" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(536, 'Generic mention professional association') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('GenericMentionProfessionalAssociation')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useGenericMentionProfessionalAssociation"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useGenericMentionProfessionalAssociation"
																				v-model="projectFieldOption.mandatoryGenericMentionProfessionalAssociation"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="divider"></div>
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(900, 'Retirement') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(901, 'Retired ?') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Retired')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useRetired"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<!-- <div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useRetired"
																				v-model="projectFieldOption.mandatoryRetired"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div> -->
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="divider"></div>
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(905, 'Study') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(170, 'Study level') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('StudyLevel')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useStudyLevel"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useStudyLevel"
																				v-model="projectFieldOption.mandatoryStudyLevel"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
													</div>
												</div>
											</div>
											<div class="divider"></div>
											<div class="text-mobile-sub-title sub-title">{{ FormMSG(907, 'Clothes') }}</div>
											<div class="content-card-collapsible">
												<div class="position-responsive">
													<div class="d-flex flex-wrap w-100 flex-column gap-1">
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(171, 'Size') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('Size')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useSize"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useSize"
																				v-model="projectFieldOption.mandatorySize"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(172, 'Pant size') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('PantSize')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.usePantSize"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.usePantSize"
																				v-model="projectFieldOption.mandatoryPantSize"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(173, 'Jacket size') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('JacketSize')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useJacketSize"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useJacketSize"
																				v-model="projectFieldOption.mandatoryJacketSize"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
														<div class="d-flex flex-row flex-wrap gap-15">
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(174, 'Shoe size') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('ShoeSize')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useShoeSize"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useShoeSize"
																				v-model="projectFieldOption.mandatoryShoeSize"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
															<b-col :cols="3" class="d-flex flex-column">
																<div class="d-flex flex-column align-items-start p-2">
																	<div class="d-flex flex-row align-items-start">
																		<label class="text-mobile" for="reference">
																			{{ FormMSG(175, 'T-shirt size') }}
																		</label>
																	</div>
																	<div class="d-flex flex-row flex align-items-center">
																		<b-button
																			@click="handleUseChange('TshirtSize')"
																			variant="primary"
																			size="sm"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				v-if="projectFieldOption.useTshirtSize"
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																			<component
																				v-else
																				:is="getLucideIcon(ICONS.EYE_OFF.name)"
																				:color="ICONS.EYE_OFF.color"
																				:size="20"
																			/>
																		</b-button>
																		<div>
																			<b-form-checkbox
																				:disabled="!projectFieldOption.useTshirtSize"
																				v-model="projectFieldOption.mandatoryTshirtSize"
																			>
																				<label class="label-mobile">{{ FormMSG(1115511, 'Mandatory') }}</label>
																			</b-form-checkbox>
																		</div>
																	</div>
																</div>
															</b-col>
														</div>
														<div class="divider-mobile"></div>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
									<div class="d-flex justify-content-end mt-4 pd-1">
										<b-button
											class="mr-1"
											type="submit"
											variant="primary"
											style="font-size: 1.25rem; letter-spacing: 0.05rem"
											@click="submitForm"
										>
											{{ FormMSG(54213, 'Save changes') }}
										</b-button>
									</div>
								</b-form>
							</div>
						</div>
					</b-row>
					<b-modal
						header-class="header-class-modal-doc-package"
						title="Success"
						class="modal-success"
						v-model="successModal"
						@ok="successModal = false"
						ok-variant="success"
						ok-only
						cancel-title="close"
					>
						{{ FormMSG(1302214, 'Your information has been saved.') }}
					</b-modal>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import { getProjectFieldOption, updProjectFieldOption } from '@/cruds/project.crud';
import CrewMemberInfoContent from '@/components/CrewMemberInfo/CrewMemberInfoContent';
import CustomDisabledCheckbox from '@/components/Forms/CustomDisabledCheckbox';
export default {
	name: 'CrewMemberInfo',
	components: {
		CrewMemberInfoContent,
		CustomDisabledCheckbox
	},
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],
	data() {
		return {
			successModal: false,
			disableSaveButton: true,
			erreur: {},
			configAccordion: {
				'personnal-data': {
					isVisible: true
				},
				'personal-vehicle': {
					isVisible: false
				},
				finance: {
					isVisible: false
				},
				health: {
					isVisible: false
				},
				identification: {
					isVisible: false
				},
				address: {
					isVisible: false
				},
				family: {
					isVisible: false
				},
				other: {
					isVisible: false
				},
				password: {
					isVisible: false
				},
				'personal-vehicle': {
					isVisible: false
				}
			},
			projectFieldOption: {
				projectId: false,
				usePersonalNumber: false,
				mandatoryPersonalNumber: false,
				useEmail: false,
				mandatoryEmail: false,
				usePhone: false,
				mandatoryPhone: false,
				useFirstName: false,
				mandatoryFirstName: false,
				useLastName: false,
				mandatoryLastName: false,
				useNationality: false,
				mandatoryNationality: false,
				useLanguage: false,
				mandatoryLanguage: false,
				useBirthdate: false,
				mandatoryBirthdate: false,
				usePlaceOfBirth: false,
				mandatoryPlaceOfBirth: false,
				useCityOfBirthZip: false,
				mandatoryCityOfBirthZip: false,
				useGender: false,
				mandatoryGender: false,
				useProfilePicture: false,
				mandatoryProfilePicture: false,
				useSocialSecurityNumber: false,
				mandatorySocialSecurityNumber: false,
				useNationalRegistrationNumber: false,
				mandatoryNationalRegistrationNumber: false,
				useCompanyNumber: false,
				mandatoryCompanyNumber: false,
				useIdName: false,
				mandatoryIdName: false,
				useIdNumber: false,
				mandatoryIdNumber: false,
				useIdPictureFront: false,
				mandatoryIdPictureFront: false,
				useIdPictureBack: false,
				mandatoryIdPictureBack: false,
				useDriverLicenseNumber: false,
				mandatoryDriverLicenseNumber: false,
				useDriverLicensePictureFront: false,
				mandatoryDriverLicensePictureFront: false,
				useDriverLicensePictureBack: false,
				mandatoryDriverLicensePictureBack: false,
				useSignaturePicture: false,
				mandatorySignaturePicture: false,
				useIban: false,
				mandatoryIban: false,
				useBic: false,
				mandatoryBic: false,
				useCountry: false,
				mandatoryCountry: false,
				useInternetResearch: false,
				mandatoryInternetResearch: false,
				useStreet: false,
				mandatoryStreet: false,
				useNumber: false,
				mandatoryNumber: false,
				useBox: false,
				mandatoryBox: false,
				useZipCode: false,
				mandatoryZipCode: false,
				useCity: false,
				mandatoryCity: false,
				useStateRegion: false,
				mandatoryStateRegion: false,
				useCivilStatus: false,
				mandatoryCivilStatus: false,
				useSpouseFirstName: false,
				mandatorySpouseFirstName: false,
				useSpouseLastName: false,
				mandatorySpouseLastName: false,
				useSpouseBirthdate: false,
				mandatorySpouseBirthdate: false,
				useSpouseHasSalary: false,
				mandatorySpouseHasSalary: false,
				useSpouseProfession: false,
				mandatorySpouseProfession: false,
				useNumberOfDependentChildren: false,
				mandatoryNumberOfDependentChildren: false,
				useNumberOfDisabledChildren: false,
				mandatoryNumberOfDisabledChildren: false,
				useNumberOfDependentPerson: false,
				mandatoryNumberOfDependentPerson: false,
				useNumberOfDisabledPerson: false,
				mandatoryNumberOfDisabledPerson: false,
				useContactName: false,
				mandatoryContactName: false,
				useContactPhoneNumber: false,
				mandatoryContactPhoneNumber: false,
				useTaxes: false,
				mandatoryTaxes: false,
				useTaxDeclarationCountry: false,
				mandatoryTaxDeclarationCountry: false,
				showMyEmailInCrewList: false,
				showMyPhoneInCrewList: false,
				useWorkerWithADisability: false,
				mandatoryWorkerWithADisability: false,
				useHaveAllergies: false,
				mandatoryHaveAllergies: false,
				useAllergiesDetails: false,
				mandatoryAllergiesDetails: false,
				useColor: false,
				mandatoryColor: false,
				useStudyLevel: false,
				mandatoryStudyLevel: false,
				useSize: false,
				mandatorySize: false,
				usePantSize: false,
				mandatoryPantSize: false,
				useJacketSize: false,
				mandatoryJacketSize: false,
				useShoeSize: false,
				mandatoryShoeSize: false,
				useTshirtSize: false,
				mandatoryTshirtSize: false,
				useMaidenName: false,
				mandatoryMaidenName: false,
				useTitle: false,
				mandatoryTitle: false,
				useCountryOfBirth: false,
				mandatoryCountryOfBirth: false,
				useCongesSpectacleId: false,
				mandatoryCongesSpectacleId: false,
				useDriverLicense: false,
				mandatoryDriverLicense: false,
				usedBankAccountOwnerName: false,
				mandatoryBankAccountOwnerName: false,
				usedBankDetailDocument: false,
				mandatoryBankDetailDocument: false,
				useSocialSecurityCard: false,
				mandatorySocialSecurityCard: false,
				useInsuranceCertificate: false,
				mandatoryInsuranceCertificate: false,
				useSocialSecurityCenter: false,
				mandatorySocialSecurityCenter: false,
				useSocialSecurityCenterAddress: false,
				mandatorySocialSecurityCenterAddress: false,
				useLastMedicalVisitDate: false,
				mandatoryLastMedicalVisitDate: false,
				useMedicalVisitValidity: false,
				mandatoryMedicalVisitValidity: false,
				useMedicalVisitDocument: false,
				mandatoryMedicalVisitDocument: false,
				useVehicleName: false,
				mandatoryVehicleName: false,
				useVehicleBrand: false,
				mandatoryVehicleBrand: false,
				useVehicleModel: false,
				mandatoryVehicleModel: false,
				useVehiclePlateNumber: false,
				mandatoryVehiclePlateNumber: false,
				useVehicleTransportationMean: false,
				mandatoryVehicleTransportationMean: false,
				useVehicleTransportationMeanDetail: false,
				mandatoryVehicleTransportationMeanDetail: false,
				useVehicleKgCoTwo: false,
				mandatoryVehicleKgCoTwo: false,
				useSncfLoyaltyCard: false,
				mandatorySncfLoyaltyCard: false,
				useSncfDiscountCard: false,
				mandatorySncfDiscountCard: false,
				useFlyingBlueCard: false,
				mandatoryFlyingBlueCard: false,
				useOtherAirlineCard: false,
				mandatoryOtherAirlineCard: false,
				useResidencePermitNumber: false,
				mandatoryResidencePermitNumber: false,
				useResidenceValidityEnd: false,
				mandatoryResidenceValidityEnd: false,
				useResidencePermit: false,
				mandatoryResidencePermit: false,
				useWorkCard: false,
				mandatoryWorkCard: false,
				usePseudonym: false,
				mandatoryPseudonym: false,
				useGenericMentionProfessionalAssociation: false,
				mandatoryGenericMentionProfessionalAssociation: false,
				useRetired: false,
				mandatoryRetired: false,
				usePower: false,
				mandatoryPower: false,
				useGrayCardFrontPicture: false,
				mandatoryGrayCardFrontPicture: false,
				useGrayCardBackPicture: false,
				mandatoryGrayCardBackPicture: false,
				useDisabilityNotificationDate: false,
				mandatoryDisabilityNotificationDate: false,
				useType: false,
				mandatoryType: false,
				useStatus: false,
				mandatoryStatus: false,
				useInvalidity: false,
				mandatoryInvalidity: false,
				useRqth: false,
				mandatoryRqth: false,
				useInvalidityPercentage: false,
				mandatoryInvalidityPercentage: false,
				useSecondIban: false,
				mandatorySecondIban: false,
				useSecondBic: false,
				mandatorySecondBic: false,
				useSecondBankAccountOwnerName: false,
				mandatorySecondBankAccountOwnerName: false,
				useSecondBankDetailDocument: false,
				mandatorySecondBankDetailDocument: false
			}
		};
	},
	async created() {
		await this.reloadData();
	},
	methods: {
		async reloadData() {
			await getProjectFieldOption().then((res) => {
				if (res) {
					// console.log('projectFieldOption', res);
					this.projectFieldOption = res;
					// console.log(res);
				}
			});
		},
		handleUseChange(field) {
			if (field === 'showMyEmailInCrewList' || field === 'showMyPhoneInCrewList') {
				this.projectFieldOption[`${field}`] = !this.projectFieldOption[`${field}`];
			} else {
				this.projectFieldOption[`use${field}`] = !this.projectFieldOption[`use${field}`];
				if (!this.projectFieldOption[`use${field}`]) {
					this.projectFieldOption[`mandatory${field}`] = false;
				}
				if (field === 'VehicleTransportationMean') {
					this.projectFieldOption[`useVehicleTransportationMeanDetail`] = this.projectFieldOption[`use${field}`];
					this.projectFieldOption[`mandatoryVehicleTransportationMeanDetail`] = this.projectFieldOption[`mandatory${field}`];
				}
			}
		},
		handleClickHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
		},
		async submitForm(e) {
			e.preventDefault();
			await updProjectFieldOption({ ...this.projectFieldOption })
				.then((res) => {
					this.projectFieldOption = res;
					this.createToastForMobile('Success', this.FormMSG(122233, 'Profile setting updated successfully'), '', 'success');
				})
				.catch((err) => {
					this.erreur = err;
					this.createToastForMobile('Error', this.FormMSG(12345879, 'There was an error while updating'), '', 'danger');
				});
		}
	},
	watch: {
		'projectFieldOption.mandatoryVehicleTransportationMean': function (newValue, oldValue) {
			console.log('NEW VALUE', newValue);
			this.projectFieldOption.mandatoryVehicleTransportationMeanDetail = newValue;
			console.log('NEW VALUE FOR MD', this.projectFieldOption.mandatoryVehicleTransportationMeanDetail);
		}
	}
};
</script>

<style scoped>
.responsive-mobile {
	background-color: rgba(226, 230, 235, 0.84);
	border-radius: 4px;
	padding: 8px 16px 8px 16px;
}
.text-mobile {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}
.text-mobile-sub-title {
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.05em;
	color: #06263e;
	margin-left: 1.2rem;
	margin-top: 1rem;
}
.text-mobile-sub-title::before {
	content: '\25BA';
	color: rgba(6, 38, 62, 0.74);
	display: inline-block;
	margin: 0 4px 0 0;
}
.gap-1 {
	gap: 10px;
}
.gap-2 {
	gap: 20px;
}
.gap-3 {
	gap: 30px;
}
.gap-4 {
	gap: 40px;
}
.gap-5 {
	gap: 50px;
}
.gap-6 {
	gap: 60px;
}
.gap-7 {
	gap: 70px;
}
.gap-8 {
	gap: 80px;
}
.gap-9 {
	gap: 90px;
}
.gap-10 {
	gap: 100px;
}

.position-responsive {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
@media screen and (max-width: 680px) {
	.position-responsive {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		flex-wrap: wrap;
	}
	.divider-mobile {
		position: relative;
		margin: 10px 0 10px 0;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 0.005rem solid rgba(226, 230, 235, 0.85);
	}
}
@media screen and (min-width: 680px) and (max-width: 996px) {
	.position-responsive {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}
@media screen and (min-width: 996px) and (max-width: 1350px) {
	.position-responsive {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}
@media screen and (min-width: 1350px) and (max-width: 1520px) {
	.position-responsive {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}
@media screen and (min-width: 1520px) {
	.position-responsive {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}
.text-mobile {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}
.avatar {
	width: 212px;
	height: 212px;
	border-radius: 50% !important;
	color: #1f2021;
	background-color: #f3f4f5;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	font-size: inherit;
	font-weight: 400;
	line-height: 1;
	max-width: 100%;
	text-align: center;
	overflow: visible;
	position: relative;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (max-width: 680px) {
	.avatar {
		width: 150px;
		height: 150px;
	}
}
@media screen and (min-width: 680px) and (max-width: 996px) {
	.avatar {
		width: 125px;
		height: 125px;
	}
}
@media screen and (min-width: 996px) and (max-width: 1350px) {
	.avatar {
		width: 125px;
		height: 125px;
	}
}
@media screen and (min-width: 1350px) and (max-width: 1520px) {
	.avatar {
		width: 212px;
		height: 212px;
	}
}
@media screen and (min-width: 1520px) {
	.avatar {
		width: 212px;
		height: 212px;
	}
}
.label-mobile {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	cursor: default;
}
.label-mobile-lg {
	display: inline-block;
	font-weight: 600;
	font-size: 14px;
	color: rgba(6, 38, 62, 0.74);
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	cursor: default;
}
/*on mobile */
/* @media only screen and (min-width: 480px) {
	.gap-15 {
		gap: 5px;
	}
} */
/* on tablet */
/* @media only screen and (min-width: 768px) {
	.gap-15 {
		gap: 80px;
	}
} */
</style>
