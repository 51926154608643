<template>
	<b-tr class="animated fadeIn" v-if="tax">
		<b-td style="vertical-align: middle; width: 2%" v-if="tax.id > 0">
			<b-form-checkbox
				v-model="tax.disabled"
				:title="checkboxTitle"
				v-b-tooltip.hover.left
				size="lg"
				:disabled="hideRemoveFirstLine"
				@change="handleCheckboxChanged($event, 'disabled')"
			/>
		</b-td>
		<b-td v-if="tax.id === 0 && existDataAlreadySubmitted"></b-td>
		<b-td style="width: 14%">
			<b-form-input type="number" step="0.01" placeholder="0" min="0" v-model="tax.factor" @keyup="handleInputChanged('factor', 2)" />
			<!-- :class="{
				'is-invalid': $v.tax.factor.$error
			}" -->
		</b-td>
		<b-td style="width: 10%">
			<b-form-input
				type="text"
				autocomplete="off"
				v-model="tax.short"
				:class="{
					'is-invalid': $v.tax.short.$error
				}"
				@keyup="handleInputChanged('short')"
			/>
		</b-td>
		<b-td :style="`width: ${tax.id > 0 ? '24' : '26'}%`">
			<b-form-input
				type="text"
				autocomplete="off"
				v-model="tax.name"
				:class="{
					'is-invalid': $v.tax.name.$error
				}"
				@keyup="handleInputChanged('name')"
			/>
		</b-td>
		<b-td style="width: 28%" class="form">
			<div class="tax-item">
				<v-tree-select
					v-model="tax.category"
					:multiple="false"
					:options="costCenter"
					:disable-branch-nodes="true"
					@input="handleInputChanged('category', 1)"
				>
					<div slot="option-label" slot-scope="{ node }">
						<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
					</div>
				</v-tree-select>
				<!-- :class="{ 'is-invalid': $v.tax.category.$error }" -->
			</div>
		</b-td>
		<b-td style="width: 19%">
			<div class="d-flex align-items-center" style="margin-top: -2px">
				<b-form-checkbox size="lg" class="mt-1" v-model="tax.digitalSubmission" @change="handleCheckboxChanged($event, 'digitalSubmission')" />
				<div class="text-left" style="color: rgba(6, 38, 62, 0.64); font-size: 0.8rem">
					{{ FormMSG(3, 'Include in digital tax submission') }}
				</div>
			</div>
		</b-td>
		<b-td class="text-right" style="vertical-align: middle; width: 3%">
			<component
				:is="getLucideIcon('MinusCircle')"
				color="#EA4E2C"
				class="cursor-pointer mr-1"
				:size="18"
				:stroke-width="3"
				@click="removeItem"
				v-if="!hideRemoveFirstLine"
			/>
		</b-td>
	</b-tr>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength, decimal } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { greaterThanZero } from '@/shared/utils';
import { INPUT_TYPE } from '@/shared/constants';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
	name: 'FinanceTableTaxItemForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		'v-tree-select': Treeselect
	},
	props: {
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		existDataAlreadySubmitted: {
			type: Boolean,
			required: false,
			default: false
		},
		hideRemoveFirstLine: {
			type: Boolean,
			required: false,
			default: false
		},
		costCenter: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	computed: {
		checkboxTitle() {
			return this.tax.disabled === false ? this.FormMSG(1, 'Disable') : this.FormMSG(2, 'Enable');
		}
	},
	data() {
		return {
			tax: null
		};
	},
	methods: {
		removeItem() {
			this.$emit('finance-table-tax-item-form:removed', {
				index: this.index,
				id: +this.tax.id
			});
		},
		handleInputChanged(fieldName, type = 0) {
			if (type === INPUT_TYPE.INTEGER) {
				this.tax[fieldName] = +this.tax[fieldName];
				if (isNaN(this.tax[fieldName])) {
					this.tax[fieldName] = 0;
				}
			} else if (type === INPUT_TYPE.FLOAT) {
				this.tax[fieldName] = parseFloat(this.tax[fieldName]);
			}

			this.$emit('finance-table-tax-item-form:updated', {
				index: this.index,
				item: this.tax,
				isLineValid: !this.$v.$invalid
			});
		},
		onDeselectTree() {
			this.tax.category = 0;
		},
		handleCheckboxChanged(payload, fieldName) {
			this.tax[fieldName] = payload;

			this.$emit('finance-table-tax-item-form:updated', {
				index: this.index,
				item: this.tax
			});
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.tax = _.cloneDeep(value);
					this.$v.$touch();
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			tax: {
				// factor: {
				// 	required,
				// 	decimal,
				// 	min: greaterThanZero
				// },
				name: {
					required,
					min: minLength(3)
				},
				short: {
					required
				}
				// category: {
				// 	required,
				// 	decimal,
				// 	min: greaterThanZero
				// }
			}
		};
	}
};
</script>
<style lang="scss">
.form {
	.tax-item {
		.vue-treeselect {
			&.is-invalid {
				.vue-treeselect__control {
					border-color: #ea4e2c !important;
				}
			}
			.vue-treeselect__control {
				display: flex;
				align-items: center;
				height: 32px !important;
				.vue-treeselect__value-container {
					display: contents;
					.vue-treeselect__placeholder,
					.vue-treeselect__single-value {
						top: -1px;
					}
				}
				.vue-treeselect__x-container {
					margin-right: 2px;
				}
			}
		}
	}
}
</style>
