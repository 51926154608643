<template>
	<b-tr class="animated fadeIn" v-if="flag">
		<b-td style="vertical-align: middle; width: 2%" v-if="flag.id > 0">
			<b-form-checkbox
				v-model="flag.disabled"
				:title="checkboxTitle"
				v-b-tooltip.hover.left
				size="lg"
				@change="handleCheckboxChanged($event, 'disabled')"
			/>
		</b-td>
		<b-td v-if="flag.id === 0 && existDataAlreadySubmitted"></b-td>
		<b-td :style="`width: ${flag.id > 0 ? '68' : '70'}%`">
			<b-form-input
				type="text"
				autocomplete="off"
				v-model="flag.name"
				:class="{
					'is-invalid': $v.flag.name.$error
				}"
				@keyup="handleInputChanged('name')"
			/>
		</b-td>
		<b-td style="width: 28%">
			<b-form-input
				type="text"
				v-model="flag.short"
				autocomplete="off"
				:class="{
					'is-invalid': $v.flag.short.$error
				}"
				@keyup="handleInputChanged('short')"
			/>
		</b-td>
		<b-td style="vertical-align: middle; width: 2%">
			<component :is="getLucideIcon('MinusCircle')" color="#EA4E2C" class="cursor-pointer mr-1" :size="18" :stroke-width="3" @click="removeItem" />
		</b-td>
	</b-tr>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { INPUT_TYPE } from '@/shared/constants';

export default {
	name: 'FinanceTableFlagItemForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	props: {
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		existDataAlreadySubmitted: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		checkboxTitle() {
			return this.flag.disabled === false ? this.FormMSG(1, 'Disable') : this.FormMSG(2, 'Enable');
		}
	},
	data() {
		return {
			flag: null
		};
	},
	methods: {
		removeItem() {
			this.$emit('finance-table-flag-item-form:removed', {
				index: this.index,
				id: +this.flag.id
			});
		},
		handleInputChanged(fieldName, type = 0) {
			if (type === INPUT_TYPE.INTEGER) {
				this.flag[fieldName] = +this.flag[fieldName];
			} else if (type === INPUT_TYPE.FLOAT) {
				this.flag[fieldName] = parseFloat(this.flag[fieldName]);
			}

			this.$emit('finance-table-flag-item-form:updated', {
				index: this.index,
				item: this.flag,
				isLineValid: !this.$v.$invalid
			});
		},
		handleCheckboxChanged(payload, fieldName) {
			this.flag[fieldName] = payload;

			this.$emit('finance-table-flag-item-form:updated', {
				index: this.index,
				item: this.flag
			});
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.flag = _.cloneDeep(value);
					this.$v.$touch();
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			flag: {
				name: {
					required,
					min: minLength(3)
				},
				short: {
					required
				}
			}
		};
	}
};
</script>
