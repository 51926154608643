<template>
	<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'" class="px-0">
		<div :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
			<div class="title" v-if="$screen.width <= 576">
				<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
					{{ FormMSG(10, 'Settings') }}
				</h1>
			</div>
			<b-col cols="12" class="w-100 px-0">
				<b-tabs
					cards
					active-nav-item-class="font-weight-bold"
					active-tab-class="font-weight-bold"
					v-model="tabs.activeTabs"
					@activate-tab="(n, p, e) => onTabClicked(n)"
				>
					<b-tab :title="getTitle">
						<ProjectForm />
					</b-tab>
					<b-tab lazy :title="FormMSG(22, 'Departments and functions')">
						<department />
					</b-tab>
					<b-tab lazy :title="labelCategorie" v-if="useFinance">
						<NewCategory />
					</b-tab>
					<b-tab lazy v-if="!isFilmSingle && !isFilmSingleFree && useFinance" :title="getMemoDealTitle" @click="refreshData">
						<memo-deals :show-pay-code="showPayCode" />
					</b-tab>
					<b-tab lazy :title="FormMSG(126545, 'User profile setting')" ref="crewMemberInfoRef">
						<crew-member-info />
					</b-tab>
					<b-tab lazy v-if="!isFilmSingle && !isFilmSingleFree && useLocations" :title="FormMSG(30, 'Premises')">
						<Premises />
					</b-tab>
					<b-tab lazy v-if="!isFilmSingle && !isFilmSingleFree && useFinance" :title="FormMSG(26, 'Finance')">
						<Finance />
					</b-tab>
				</b-tabs>
			</b-col>
		</div>
	</div>
</template>

<script>
import NewCategory from './budget/NewCategoryScreen';
import MemoDeals from './projects/MemoDeals';
import ProjectForm from './projects/ProjectForm';
import Department from './department/department';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import CrewMemberInfo from '@/views/crewMemberInfo/CrewMemberInfo';
import { store } from '@/store';
import { addLicenseRequestForAndroidSubscription } from '@/cruds/licenseRequest.crud';
import { isNil } from '@/shared/utils';
import Premises from './Premises/premises.vue';
import Finance from '@/components/Finance/Finance';
import TimeManagement from '../components/Finance/TimeManagement.vue';
import activeTabMixin from '@/mixins/activeTab.mixin';

export default {
	name: 'ProjectAdministration',
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin, activeTabMixin],
	components: {
		CrewMemberInfo,
		MemoDeals,
		ProjectForm,
		Department,
		NewCategory,
		Premises,
		Finance,
		TimeManagement
	},
	data() {
		return {
			carbonModel: 0,
			showModalConfirmationIOS: false,
			showPayCode: false
		};
	},
	watch: {
		'$route.query.tabs': {
			handler() {
				setTimeout(() => {
					if (this.$route.query.tabs) {
						this.handleTabsQuery();
					} else {
						this.tabs.activeTabs = 0;
					}
				}, 300);
			},
			immediate: true,
			deep: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.carbonModel = store.getCurrentProjectConfig().carbonModel;
		});
		this.$bus.$on('bus:projectevent', (payload) => {
			this.carbonModel = payload.carbonModel;
		});

		if (this.$route.query.activeTabs && this.$route.query.activeTabs === 'subscriptions') {
			this.$nextTick(() => {
				if (this.$refs.crewMemberInfoRef) this.$refs.crewMemberInfoRef.activate();
				// this.tabs.activeTabs = 4;
			});
		}
	},
	created() {
		this.initTabs();
	},
	computed: {
		useLocations() {
			// return store.getCurrentProjectConfig().useLocations;
			return store.getCurrentProjectConfig().useLocations;
		},
		useFinance() {
			// return store.getCurrentProjectConfig().useFinance;
			return store.getCurrentProjectConfig().useFinance;
		},
		labelCategorie() {
			return this.FormMSG(21, 'Budget categories');
		},
		getMemoDealTitle() {
			if (store.isPme()) {
				return this.FormMSG(123, 'HR templates');
			}
			return this.FormMSG(23, 'Memo deal templates');
		},
		getTitle() {
			if (store.isPme()) {
				return this.FormMSG(120, 'Legal entity information');
			}
			return this.FormMSG(20, 'Project information');
		},
		projectData() {
			return store.getCurrentProjectConfig();
		},
		profileData() {
			return store.state.myProfile;
		}
	},
	// mounted() {
	// 	// Check if there's an argument to open subscription tabs
	// 	if (this.$route.query && this.$route.query.activeTabs === 'subscriptions') {
	// 		this.$nextTick(() => {
	// 			this.tabIndex = 4;
	// 		});
	// 	}
	// },
	methods: {
		handleClickManageSubscriptionAndroid() {
			const action = async () => {
				const resultAddLicenseRequest = await addLicenseRequestForAndroidSubscription({
					email: store.state.myProfile.email,
					phone: store.state.myProfile.phone,
					projectTitle: store.getCurrentProjectConfig().titre,
					userId: parseInt(store.state.myProfile.id),
					requestType: 4,
					userName: store.state.myProfile.name,
					userFirstName: store.state.myProfile.firstName,
					language: store.appLanguage()
				});

				const url = `${process.env.BASE_URL}/#/pages/login?MS=${resultAddLicenseRequest.xid}`;
				window.open(url, '', 'width=300, height=500');
			};

			this.confirmModal(action, this.FormMSG(49, 'You will be redirected to an external page to follow your subscription management. Want to continue?'));
		},
		handleClickHere() {
			const url = 'http://www.thegreenshot.green/';
			var temp = document.createElement('textarea');
			var tempMsg = document.createTextNode(url);
			temp.appendChild(tempMsg);

			document.body.appendChild(temp);
			temp.select();
			document.execCommand('copy');
			document.body.removeChild(temp);

			this.createToastForMobile(this.FormMSG(50, 'Copy successfully'));
		},
		refreshData() {
			this.showPayCode = store.getCurrentProjectConfig().payrollInterface === 0 ? false : true;
		}
	}
};
</script>

<style lang="scss" scoped>
.tab-project {
	background-color: #ffffff;
	border-radius: 2px;
	// padding: 3px 4px 3px 4px;
}
</style>
