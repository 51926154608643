<template>
	<div class="animated fadeIn" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<b-form @submit.prevent="submitForm">
			<b-row class="mb-2">
				<b-col>
					<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(8, 'General') }}
						</legend>
						<b-row :class="`${$screen.width <= 576 ? 'pb-2' : 'py-2'}`">
							<b-col sm="8">
								<b-form-group :label="FormMSG(2, 'Category')">
									<b-form-input
										type="text"
										autocomplete="off"
										v-model="tax.name"
										:class="{
											'is-invalid': $v.tax.name.$error
										}"
									/>
									<div v-if="$v.tax.name.$error && $screen.width <= 576" class="invalid-feedback">
										{{ FormMSG(3, 'Please, category name is required') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="2">
								<b-form-group :label="FormMSG(4, 'Abbreviation')">
									<b-form-input
										type="text"
										autocomplete="off"
										v-model="tax.short"
										:class="{
											'is-invalid': $v.tax.short.$error
										}"
									/>
									<div v-if="$v.tax.short.$error && $screen.width <= 576" class="invalid-feedback">
										{{ FormMSG(5, 'Please, short name is required') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="2">
								<b-form-group :label="'Mandatory'">
									<b-form-checkbox
										size="lg"
										v-model="tax.mandatoryFlag"
										:class="`${$screen.width <= 992 ? '' : 'mt-1'}`"
										:style="`${$screen.width <= 992 ? 'margin-top: -4px;' : ''}`"
									/>
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="my-2">
				<b-col>
					<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(6, 'Information') }}
						</legend>
						<b-row :class="`${$screen.width <= 576 ? 'pb-2' : 'py-2'}`">
							<b-col>
								<b-form-group :label="FormMSG(7, 'Description')">
									<b-form-textarea v-model="tax.description" type="textarea" rows="2" autocomplete="off" />
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row :class="`${$screen.width <= 992 ? 'mt-2 pb-4' : 'mt-2 pb-2'}`">
				<b-col>
					<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(1, 'Codes') }}
						</legend>
						<b-row class="mt-2">
							<b-col>
								<card-list-builder
									v-if="$screen.width < 992 && tax.projectFlagItem.length > 0"
									:use-new-version="true"
									:toggle-mode="true"
									:items="tax.projectFlagItem"
									:fields="taxFields"
									:validators="validatorPatterns"
									:class="`${tax.projectFlagItem.length > 0 ? 'pb-3' : ''}`"
								>
									<template slot="disabled" slot-scope="{ item, index }" v-if="item.id > 0">
										<b-form-checkbox
											v-model="item.disabled"
											:title="checkboxTitle(item)"
											v-b-tooltip.focus.right
											size="lg"
											:disabled="patternForHidingRemoveBtnInTaxItem(index)"
											@change="handleCheckboxChanged($event, 'disabled', index)"
										/>
									</template>
									<template slot="factor" slot-scope="{ item, error, index, isLineValid }">
										<b-form-input
											size="sm"
											type="number"
											autocomplete="off"
											step="0.01"
											placeholder="0"
											min="0"
											v-model="item.factor"
											:class="{
												'is-invalid': error
											}"
											@keyup="handleInputChanged(item.factor, 'factor', index, isLineValid, 2)"
										/>
									</template>
									<template slot="short" slot-scope="{ item, error, index, isLineValid }">
										<b-form-input
											size="sm"
											type="text"
											autocomplete="off"
											v-model="item.short"
											:class="{
												'is-invalid': error
											}"
											@keyup="handleInputChanged(item.short, 'short', index, isLineValid)"
										/>
									</template>
									<template slot="name" slot-scope="{ item, error, index, isLineValid }">
										<b-form-input
											size="sm"
											type="text"
											autocomplete="off"
											v-model="item.name"
											:class="{
												'is-invalid': error
											}"
											@keyup="handleInputChanged(item.name, 'name', index, isLineValid)"
										/>
									</template>
									<template slot="category" slot-scope="{ item, error, index, isLineValid }">
										<div
											class="inline-tax-item"
											:ref="generateTextId(`tree-select-${index}`)"
											@click.prevent="fixEventTreeselect(item.category, 'category', isLineValid, index)"
										>
											<v-tree-select
												v-model="item.category"
												:multiple="false"
												:options="taxCostCenter"
												:disable-branch-nodes="true"
												:class="{ 'is-invalid': error }"
												@input="handleInputChanged(item.category, 'category', index, isLineValid, 1)"
											>
												<div slot="option-label" slot-scope="{ node }">
													<div :title="node.label">{{ node.label }}</div>
												</div>
											</v-tree-select>
										</div>
									</template>
									<template slot="digitalSubmission" slot-scope="{ item, index }">
										<b-form-checkbox
											v-model="item.digitalSubmission"
											size="lg"
											@change="handleCheckboxChanged($event, 'digitalSubmission', index)"
										/>
									</template>
									<template slot="actions" slot-scope="data">
										<b-button
											variant="danger"
											size="sm"
											class="ml-2 btn bg-transparent border-0"
											@click="
												onLineTableRemoved({
													index: data.index,
													id: data.item.id
												})
											"
											v-if="!patternForHidingRemoveBtnInTaxItem(data.index)"
										>
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</template>
								</card-list-builder>
								<div class="text-center pb-3 mb-2" style="font-size: 1rem" v-if="$screen.width < 992 && tax.projectFlagItem.length === 0">
									{{ FormMSG(15, 'Please, add a new tax') }}
								</div>
								<b-table-simple small :borderless="true" v-if="$screen.width >= 992 && tax.projectFlagItem.length > 0">
									<b-thead style="color: rgba(6, 38, 62, 0.84)">
										<b-tr style="line-height: 8px">
											<b-th v-if="isDataExist && tax.projectFlagItem.length > 0"></b-th>
											<b-th>%</b-th>
											<b-th>{{ FormMSG(10, 'Ref.') }}</b-th>
											<b-th>{{ FormMSG(11, 'Description') }}</b-th>
											<b-th>{{ FormMSG(12, 'Control account') }}</b-th>
											<b-th></b-th>
											<b-th></b-th>
										</b-tr>
									</b-thead>
									<b-tbody class="text-center">
										<item-form
											v-for="(item, i) in tax.projectFlagItem"
											:ref="generateTextId(`item-form-${i}`)"
											:key="i"
											:index="i"
											:item="item"
											:hide-remove-first-line="patternForHidingRemoveBtnInTaxItem(i)"
											:cost-center="taxCostCenter"
											:existDataAlreadySubmitted="existDataAlreadySubmitted"
											@finance-table-tax-item-form:updated="onLineTableChanged"
											@finance-table-tax-item-form:removed="onLineTableRemoved"
										/>
									</b-tbody>
								</b-table-simple>
								<div class="text-center pb-3" style="font-size: 1rem" v-if="$screen.width >= 992 && tax.projectFlagItem.length === 0">
									{{ FormMSG(15, 'Please, add a new tax') }}
								</div>
							</b-col>
						</b-row>
						<b-row class="mb-2 pb-3">
							<div class="d-flex justify-content-end" style="width: 93.5%">
								<b-button
									size="sm"
									variant="outline-primary"
									class="w-200-px d-flex justify-content-center align-items-center"
									:disabled="$v.$invalid"
									@click="addNewTax"
								>
									<component :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
									<div class="px-2" style="margin-bottom: -2px">
										{{ FormMSG(13, 'Add new tax code') }}
									</div>
								</b-button>
							</div>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					class="d-flex justify-content-center mt-2"
					sm="4"
					md="4"
					lg="4"
					xl="4"
					offset-sm="5"
					offset-md="5"
					offset-lg="5"
					offset-xl="5"
					:style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`"
				>
					<b-button ref="submit" type="submit" size="md" variant="outline-primary" class="w-138-px" block>{{ FormMSG(14, 'Save') }}</b-button>
				</b-col>
			</b-row>
		</b-form>
		<b-modal
			header-class="header-class-modal-doc-package"
			header-bg-variant="warning"
			:title="this.FormMSG(17, 'Warning')"
			class="modal-warning"
			v-model="warningMissingFlagItemModal"
			@ok="warningMissingFlagItemModal = false"
			ok-variant="warning"
			ok-only
		>
			<div style="font-size: 1rem">
				{{ FormMSG(18, 'Please fill the field(s) required (coloring red) in codes section or remove one or all of them.') }}
			</div>
		</b-modal>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength, decimal } from 'vuelidate/lib/validators';
import { addUpdateProjectFlag, addUpdateProjectFlagItem, deleteProjectFlagItem } from '@/cruds/flag.crud';
import ItemForm from '@/components/Finance/TableTaxItemForm';
import { removeAttributeTree, generateSecureId } from '@/shared/utils';
import { ACTION, FLAG_TYPE, INPUT_TYPE } from '@/shared/constants';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { greaterThanZero } from '@/shared/utils';

export default {
	name: 'FinanceTaxForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		ItemForm,
		'v-tree-select': Treeselect
	},
	props: {
		action: {
			type: String,
			required: true,
			default: ACTION.ADD // ADD, UPDATE
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: true
		},
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		isDataExist: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		taxFields() {
			return [
				{
					key: 'disabled',
					label: this.FormMSG(24, 'Disable ?'),
					class: 'text-center',
					isSlot: true
				},

				{
					key: 'factor',
					label: '%',
					class: 'text-center',
					isSlot: true,
					required: true
				},
				{
					key: 'short',
					label: this.FormMSG(10, 'Taxe ref.'),
					class: 'text-center',
					isSlot: true,
					required: true
				},
				{
					key: 'name',
					label: this.FormMSG(11, 'Description'),
					class: 'text-center',
					isSlot: true,
					required: true
				},
				{
					key: 'category',
					label: this.FormMSG(12, 'Control account'),
					class: 'text-center',
					isSlot: true,
					required: true
				},
				{
					key: 'digitalSubmission',
					label: this.FormMSG(9, 'Include in digital tax submission'),
					class: 'text-center',
					isSlot: true
				}
			];
		}
	},
	data() {
		return {
			tax: {
				id: 0,
				name: '',
				short: '',
				decription: '',
				projectFlagItem: []
			},
			isSubmitted: false,
			// taxes: [],
			isValidFlags: [],
			warningMissingFlagItemModal: false,
			taxCostCenter: [],
			existDataAlreadySubmitted: false,
			validatorPatterns: {
				factor: {
					required,
					decimal,
					min: greaterThanZero
				},
				name: {
					required,
					min: minLength(3)
				},
				short: {
					required
				},
				category: {
					required,
					decimal,
					min: greaterThanZero
				}
			},
			addUpdFlagItems: []
		};
	},
	async created() {
		await this.initCostCenter();
		this.$v.$touch();
	},
	methods: {
		async submitForm(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid || !this.validFlags()) {
				if (!this.validFlags()) {
					this.warningMissingFlagItemModal = true;
				}
				return;
			}

			if (this.action === ACTION.ADD) {
				await this.manageAction(0);
			} else if (this.action === ACTION.UPDATE) {
				await this.manageAction(this.tax.id);
			}
		},
		async manageAction(id = 0) {
			this.$emit('finance-tax-form:waiting', true);
			await addUpdateProjectFlag(+id, {
				name: this.tax.name,
				short: this.tax.short,
				description: this.tax.description,
				mandatoryFlag: this.tax.mandatoryFlag,
				type: FLAG_TYPE.TAX
			})
				.then(async (record) => {
					for (const flagItem of this.addUpdFlagItems) {
						let item = {
							name: flagItem.name,
							short: flagItem.short,
							factor: +flagItem.factor,
							category: flagItem.category,
							digitalSubmission: flagItem.digitalSubmission
						};

						if (+flagItem.id === 0) {
							item = {
								flagId: record.flagId,
								...item
							};
						} else {
							item = {
								disabled: flagItem.disabled,
								...item
							};
						}

						await addUpdateProjectFlagItem(+flagItem.id, item).catch((error) => {
							console.error({ error });
						});
					}
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					let message = '';
					if (this.action === ACTION.ADD) {
						message =
							this.tax.projectFlagItem.length === 0
								? this.FormMSG(19, 'Flag category created successfully, but you need to create codes later.')
								: this.FormMSG(20, 'Flag category and codes created successfully.');
					} else if (this.action === ACTION.UPDATE) {
						message =
							this.tax.projectFlagItem.length === 0
								? this.FormMSG(21, 'Flag category updated successfully, but you need to create codes later.')
								: this.FormMSG(22, 'Flag category and codes updated successfully.');
					}
					this.$emit('finance-tax-form:waiting', false);
					this.$emit('finance-tax-form:close-modal', message);
				});
		},
		onLineTableChanged({ index, item, isLineValid }) {
			if (this.addUpdFlagItems.length === 0) {
				this.addUpdFlagItems.push({
					...item,
					index
				});
			} else {
				const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
				if (idx === -1) {
					this.addUpdFlagItems.push({
						...item,
						index
					});
				} else {
					this.addUpdFlagItems[idx] = Object.assign(this.addUpdFlagItems[idx], item);
				}
			}

			this.isValidFlags[index] = isLineValid;
		},
		onLineTableRemoved({ index, id }) {
			// console.log({ projectFlagItemBefore: this.tax.projectFlagItem });
			if (id === 0) {
				this.tax.projectFlagItem.splice(index, 1);
				const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
				if (idx !== -1) {
					this.addUpdFlagItems.splice(idx, 1);
				}
				this.isValidFlags.splice(index, 1);
			} else {
				const action = async () => {
					await deleteProjectFlagItem(id)
						.then((result) => {
							if (result) {
								this.tax.projectFlagItem.splice(index, 1);
								// console.log({ projectFlagItemAfter: this.tax.projectFlagItem });
								const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
								if (idx !== -1) {
									this.addUpdFlagItems.splice(idx, 1);
								}
								this.isValidFlags.splice(index, 1);

								this.$emit('finance-tax-form:reload', true);
							}
						})
						.catch((e) => {
							console.error({ e });
						});
				};
				this.confirmModal(action, this.FormMSG(16, 'Are you sure to remove this code ?'), 'danger');
			}

			if (this.tax.projectFlagItem.length === 0) {
				this.existDataAlreadySubmitted = false;
			} else {
				const existTrueData = this.tax.projectFlagItem.filter((taxItem) => +taxItem.id > 0);
				this.existDataAlreadySubmitted = existTrueData.length > 0;
			}
		},
		addNewTax() {
			const flagItem = {
				id: 0,
				flagId: 0,
				name: '',
				short: '',
				factor: '',
				category: 0,
				digitalSubmission: false
			};

			this.tax.projectFlagItem.push(flagItem);
			this.isValidFlags.push(false);
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		validFlags() {
			if (this.isValidFlags.length === 0) return true;
			let isValidFlags = this.isValidFlags.join(',');

			return !/false/.test(isValidFlags);
		},
		async initCostCenter() {
			await getBudgetHierarchicalCategoryJson(-1, true, -1).then((result) => {
				const removeNoneChildrenTree = removeAttributeTree(result, 'children', null);
				this.taxCostCenter = [
					{
						id: 0,
						label: this.FormMSG(23, 'Select a category')
					},
					...removeNoneChildrenTree
				];
			});
		},
		patternForHidingRemoveBtnInTaxItem(index) {
			if (this.tax.flagId === 0) {
				if (index === 0) {
					return true;
				}
			}

			return false;
		},
		checkboxTitle(item) {
			return item.disabled === false ? this.FormMSG(25, 'Disable') : this.FormMSG(26, 'Enable');
		},
		handleInputChanged(payload, fieldName, index, isLineValid, type = 0) {
			if (this.addUpdFlagItems.length === 0) {
				let flagItem = this.tax.projectFlagItem[index];

				if (type === INPUT_TYPE.INTEGER) {
					flagItem[fieldName] = +payload;
				} else if (type === INPUT_TYPE.FLOAT) {
					flagItem[fieldName] = parseFloat(payload);
				} else {
					flagItem[fieldName] = payload;
				}

				this.addUpdFlagItems.push({
					...flagItem,
					index
				});
			} else {
				const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
				if (idx === -1) {
					let flagItem = this.tax.projectFlagItem[index];

					if (type === INPUT_TYPE.INTEGER) {
						flagItem[fieldName] = +payload;
					} else if (type === INPUT_TYPE.FLOAT) {
						flagItem[fieldName] = parseFloat(payload);
					} else {
						flagItem[fieldName] = payload;
					}

					this.addUpdFlagItems.push({
						...flagItem,
						index
					});
				} else {
					if (type === INPUT_TYPE.INTEGER) {
						this.addUpdFlagItems[idx][fieldName] = +payload;
					} else if (type === INPUT_TYPE.FLOAT) {
						this.addUpdFlagItems[idx][fieldName] = parseFloat(payload);
					} else {
						this.addUpdFlagItems[idx][fieldName] = payload;
					}
				}
			}

			if (fieldName === 'category') {
				this.$refs[this.generateTextId(`tree-select-${index}`)].click();
			}

			this.isValidFlags[index] = isLineValid;
		},
		handleCheckboxChanged(payload, fieldName, index) {
			if (this.addUpdFlagItems.length === 0) {
				let flagItem = this.tax.projectFlagItem[index];
				flagItem[fieldName] = payload;

				this.addUpdFlagItems.push({
					...flagItem,
					index
				});
			} else {
				const idx = this.addUpdFlagItems.findIndex((flagItem) => flagItem.index === index);
				if (idx === -1) {
					let flagItem = this.tax.projectFlagItem[index];

					flagItem[fieldName] = payload;

					this.addUpdFlagItems.push({
						...flagItem,
						index
					});
				} else {
					this.addUpdFlagItems[idx][fieldName] = payload;
				}
			}
		},
		fixEventTreeselect(payload, fieldName, isLineValid, index) {
			if (!_.isNil(this.addUpdFlagItems[index])) {
				this.addUpdFlagItems[index][fieldName] = +payload;
			}
			this.isValidFlags[index] = isLineValid;
		}
	},
	watch: {
		item: {
			handler(value) {
				if (this.action === ACTION.UPDATE) {
					if (!_.isNil(value)) {
						this.tax = _.cloneDeep(value);
						if (this.tax.projectFlagItem.length > 0) {
							this.tax.projectFlagItem.forEach(() => {
								this.isValidFlags.push(true);
								this.existDataAlreadySubmitted = true;
							});
						}
					}
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			tax: {
				name: {
					required,
					min: minLength(3)
				},
				short: {
					required
				}
			}
		};
	}
};
</script>
<style lang="scss">
.inline-tax-item {
	position: absolute;
	width: 48.5%;
	margin-top: -14px;
	.vue-treeselect {
		&.is-invalid {
			.vue-treeselect__control {
				border-color: #ea4e2c !important;
			}
		}
		.vue-treeselect__control {
			display: flex;
			align-items: center;
			height: 28px !important;
			.vue-treeselect__value-container {
				display: contents;
				.vue-treeselect__placeholder,
				.vue-treeselect__single-value {
					top: -3px;
				}
				.vue-treeselect__input-container {
					.vue-treeselect__input {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						color: rgba(6, 38, 62, 0.84);
					}
				}
			}
			.vue-treeselect__x-container {
				margin-right: 2px;
			}
		}
		.vue-treeselect__menu-container {
			.vue-treeselect__menu {
				font-size: 12px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: rgba(6, 38, 62, 0.74);
			}
		}
	}
}
</style>
