<template>
	<span class="custom-input-checkbox-disabled">
		<input :disabled="isDisable" :name="name" type="checkbox" :value="value" :checked="value" />
		<label class="label-mobile" :for="name">{{ label }}</label>
	</span>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'CustomDisabledCheckbox',

	mixins: [globalMixin],

	props: {
		label: { type: String, default: '', required: false },
		name: { type: String, default: '', required: false },
		value: { type: Boolean, required: true },
		isDisable: { type: Boolean, default: false, required: true },
		useSmallFont: { type: Boolean, default: false, required: false }
	}
};
</script>

<style lang="scss" scoped>
.custom-input-checkbox-disabled {
	display: flex;
	align-items: center;
	gap: 5px;
	/* Hide the actual checkbox */
	input[type='checkbox'] {
		display: none;
	}

	/* Style the label to look like a checkbox */
	label:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		margin-right: 9px;
		margin-left: 2px;
		width: 11px;
		height: 11px;
		background-color: #00a09c;
		border: 1px solid transparent;
		border-radius: 2px;
	}

	/* Style the label to look like a checked checkbox */
	input[type='checkbox']:checked + label:before {
		background-image: url('https://icons8.com/icon/TUfmJDlYw1B4/done');
		background-repeat: no-repeat;
		background-position: center;
	}

	/* Style the label to look like a disabled checkbox */
	input[type='checkbox']:disabled + label:before {
		background-color: #eee;
		border-color: transparent;
	}

	/* Style the label to look like a checked and disabled checkbox */
	input[type='checkbox']:checked:disabled + label:before {
		//background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='15' height='15' viewBox='-3 -3 52 52'%3E%3Cpath fill='%23ffffff' stroke='%23ffffff' stroke-width='4' d='M 42.960938 8.9804688 A 2.0002 2.0002 0 0 0 41.585938 9.5859375 L 17 34.171875 L 6.4140625 23.585938 A 2.0002 2.0002 0 1 0 3.5859375 26.414062 L 15.585938 38.414062 A 2.0002 2.0002 0 0 0 18.414062 38.414062 L 44.414062 12.414062 A 2.0002 2.0002 0 0 0 42.960938 8.9804688 z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center;
		background-color: #00a09c;
	}
}
</style>
