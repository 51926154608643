<template>
	<div v-if="isAllDisableComponent" class="d-flex flex-column align-items-start p-2">
		<div class="d-flex flex-row align-items-start">
			<label class="text-mobile" :disabled="isAllDisableComponent" for="reference"> {{ title }} </label>
		</div>
		<div class="d-flex flex-row justify-content-center" style="gap: 5px">
			<div class="d-flex flex-row">
				<b-button :disabled="isAllDisableComponent" variant="primary" size="sm" class="btn bg-transparent border-0">
					<component :is="getLucideIcon(ICONS.EYE_OFF.name)" :color="ICONS.EYE.color" :size="20" />
				</b-button>
			</div>
			<div class="d-flex align-items-center justify-content-center">
				<div class="d-flex justify-content-center align-items-center">
					<b-form-checkbox size="lg" :disabled="isAllDisableComponent" v-model="value.isMandatory" />
					<label class="label-mobile-lg">Mandatory</label>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="d-flex flex-column align-items-start p-2">
		<div v-if="!isShowPicture && !isNotShowCheck" class="d-flex flex-row align-items-start">
			<label class="text-mobile" for="reference"> {{ title }} </label>
		</div>
		<div v-if="isShowPicture && !isNotShowCheck">
			<b-avatar class="avatar"></b-avatar>
		</div>
		<div v-if="isRequiredOption" class="d-flex flex-row justify-content-center" style="gap: 5px">
			<div class="d-flex flex-row">
				<b-button :disabled="isRequiredOption" variant="primary" size="sm" class="btn bg-transparent border-0">
					<component v-if="value.isActive" :is="getLucideIcon(ICONS.EYE.name)" :color="ICONS.EYE.color" :size="20" />
					<component v-else :is="getLucideIcon(ICONS.EYE_OFF.name)" :color="ICONS.EYE_OFF.color" :size="20" />
				</b-button>
			</div>
			<div v-if="isShowMandatoryText" class="d-flex align-items-center justify-content-center">
				<div>
					<b-form-checkbox @change="checkActionIsMandatory(value.isMandatory)" :disabled="isRequiredOption" v-model="value.isMandatory">
						<label class="label-mobile">Mandatory</label>
					</b-form-checkbox>
				</div>
			</div>
		</div>
		<div v-else :class="`${isShowPicture ? 'd-flex flex-row justify-content-center w-100' : 'd-flex flex-row justify-content-center'}`" style="gap: 5px">
			<div class="d-flex flex-row">
				<b-button @click="checkActionIsActive(value.isActive)" variant="primary" size="sm" class="btn bg-transparent border-0">
					<component v-if="value.isActive" :is="getLucideIcon(ICONS.EYE.name)" :color="ICONS.EYE.color" :size="20" />
					<component v-else :is="getLucideIcon(ICONS.EYE_OFF.name)" :color="ICONS.EYE_OFF.color" :size="20" />
				</b-button>
			</div>
			<div v-if="isShowMandatoryText" class="d-flex align-items-center justify-content-center">
				<div>
					<b-form-checkbox @change="checkActionIsMandatory(value.isMandatory)" v-model="value.isMandatory">
						<label class="label-mobile">Mandatory</label>
					</b-form-checkbox>
				</div>
			</div>
			<div class="d-flex align-items-center justify-content-center">
				<div v-if="isNotShowCheck">
					<label class="text-mobile">{{ title }}</label>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'CrewMemberInfoContent',
	mixins: [languageMessages, validationMixin, globalMixin],
	props: {
		title: {
			type: String,
			required: false
		},
		isShowMandatoryText: {
			type: Boolean,
			default: false,
			required: false
		},
		isAllDisableComponent: {
			type: Boolean,
			default: false,
			required: false
		},
		isRequiredOption: {
			type: Boolean,
			default: false,
			required: false
		},
		isCheckDefault: {
			type: Boolean,
			default: false,
			required: false
		},
		isShowPicture: {
			type: Boolean,
			default: false,
			required: false
		},
		isNotShowCheck: {
			type: Boolean,
			default: false,
			required: false
		},
		value: {
			type: Object,
			default: () => ({
				isActive: false,
				isMandatory: false
			}),
			required: false
		},
		champ: {
			type: String,
			required: false
		},
		field: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			checkedIsActive: false,
			checkedIsMandatory: false
		};
	},
	methods: {
		setName(name, value) {
			let obj = {};
			obj[name] = value;
			return obj;
		},
		checkActionIsActive(e) {
			this.checkedIsActive = !e;
			this.checkedIsMandatory = this.value.isMandatory;
			this.$emit('checkAction', {
				field: this.field,
				champ: this.champ,
				isActive: this.checkedIsActive,
				isMandatory: this.value.isMandatory
			});
		},
		checkActionIsMandatory(e) {
			this.checkedIsActive = this.value.isActive;
			this.checkedIsMandatory = e;
			this.$emit('checkAction', {
				field: this.field,
				champ: this.champ,
				isActive: this.checkedIsActive,
				isMandatory: this.checkedIsMandatory
			});
		}
	},
	watch: {}
};
</script>
<style scoped>
.text-mobile {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}
.avatar {
	width: 212px;
	height: 212px;
	border-radius: 50% !important;
	color: #1f2021;
	background-color: #f3f4f5;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	font-size: inherit;
	font-weight: 400;
	line-height: 1;
	max-width: 100%;
	text-align: center;
	overflow: visible;
	position: relative;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (max-width: 680px) {
	.avatar {
		width: 150px;
		height: 150px;
	}
}
@media screen and (min-width: 680px) and (max-width: 996px) {
	.avatar {
		width: 125px;
		height: 125px;
	}
}
@media screen and (min-width: 996px) and (max-width: 1350px) {
	.avatar {
		width: 125px;
		height: 125px;
	}
}
@media screen and (min-width: 1350px) and (max-width: 1520px) {
	.avatar {
		width: 212px;
		height: 212px;
	}
}
@media screen and (min-width: 1520px) {
	.avatar {
		width: 212px;
		height: 212px;
	}
}
.label-mobile {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	cursor: default;
}
.label-mobile-lg {
	display: inline-block;
	font-weight: 600;
	font-size: 14px;
	color: rgba(6, 38, 62, 0.74);
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	cursor: default;
}
</style>
