var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "custom-input-checkbox-disabled" }, [
    _c("input", {
      attrs: { disabled: _vm.isDisable, name: _vm.name, type: "checkbox" },
      domProps: { value: _vm.value, checked: _vm.value },
    }),
    _c("label", { staticClass: "label-mobile", attrs: { for: _vm.name } }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }