var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tax
    ? _c(
        "b-tr",
        { staticClass: "animated fadeIn" },
        [
          _vm.tax.id > 0
            ? _c(
                "b-td",
                { staticStyle: { "vertical-align": "middle", width: "2%" } },
                [
                  _c("b-form-checkbox", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left",
                        modifiers: { hover: true, left: true },
                      },
                    ],
                    attrs: {
                      title: _vm.checkboxTitle,
                      size: "lg",
                      disabled: _vm.hideRemoveFirstLine,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckboxChanged($event, "disabled")
                      },
                    },
                    model: {
                      value: _vm.tax.disabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.tax, "disabled", $$v)
                      },
                      expression: "tax.disabled",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.tax.id === 0 && _vm.existDataAlreadySubmitted
            ? _c("b-td")
            : _vm._e(),
          _c(
            "b-td",
            { staticStyle: { width: "14%" } },
            [
              _c("b-form-input", {
                attrs: {
                  type: "number",
                  step: "0.01",
                  placeholder: "0",
                  min: "0",
                },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("factor", 2)
                  },
                },
                model: {
                  value: _vm.tax.factor,
                  callback: function ($$v) {
                    _vm.$set(_vm.tax, "factor", $$v)
                  },
                  expression: "tax.factor",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            { staticStyle: { width: "10%" } },
            [
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.tax.short.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("short")
                  },
                },
                model: {
                  value: _vm.tax.short,
                  callback: function ($$v) {
                    _vm.$set(_vm.tax, "short", $$v)
                  },
                  expression: "tax.short",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            { style: `width: ${_vm.tax.id > 0 ? "24" : "26"}%` },
            [
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.tax.name.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("name")
                  },
                },
                model: {
                  value: _vm.tax.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.tax, "name", $$v)
                  },
                  expression: "tax.name",
                },
              }),
            ],
            1
          ),
          _c("b-td", { staticClass: "form", staticStyle: { width: "28%" } }, [
            _c(
              "div",
              { staticClass: "tax-item" },
              [
                _c("v-tree-select", {
                  attrs: {
                    multiple: false,
                    options: _vm.costCenter,
                    "disable-branch-nodes": true,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.handleInputChanged("category", 1)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option-label",
                        fn: function ({ node }) {
                          return _c("div", {}, [
                            _c(
                              "div",
                              {
                                staticClass: "treeselect-label",
                                attrs: { title: node.label },
                              },
                              [_vm._v(_vm._s(node.label))]
                            ),
                          ])
                        },
                      },
                    ],
                    null,
                    false,
                    2657349975
                  ),
                  model: {
                    value: _vm.tax.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.tax, "category", $$v)
                    },
                    expression: "tax.category",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("b-td", { staticStyle: { width: "19%" } }, [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center",
                staticStyle: { "margin-top": "-2px" },
              },
              [
                _c("b-form-checkbox", {
                  staticClass: "mt-1",
                  attrs: { size: "lg" },
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckboxChanged(
                        $event,
                        "digitalSubmission"
                      )
                    },
                  },
                  model: {
                    value: _vm.tax.digitalSubmission,
                    callback: function ($$v) {
                      _vm.$set(_vm.tax, "digitalSubmission", $$v)
                    },
                    expression: "tax.digitalSubmission",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "text-left",
                    staticStyle: {
                      color: "rgba(6, 38, 62, 0.64)",
                      "font-size": "0.8rem",
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(3, "Include in digital tax submission")
                        ) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-td",
            {
              staticClass: "text-right",
              staticStyle: { "vertical-align": "middle", width: "3%" },
            },
            [
              !_vm.hideRemoveFirstLine
                ? _c(_vm.getLucideIcon("MinusCircle"), {
                    tag: "component",
                    staticClass: "cursor-pointer mr-1",
                    attrs: { color: "#EA4E2C", size: 18, "stroke-width": 3 },
                    on: { click: _vm.removeItem },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }