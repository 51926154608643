import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

const commonAddFields = `
	email
	xid
`;

const commonFields = `
	licenseOwner
	projectTitle
	userName
	userFirstName
`;

export const addLicenseRequestForAndroidSubscription = async (input) => {
	try {
		const {
			data: { AddLicenseRequestForAndroidSubscription }
		} = await apollo.mutate({
			mutation: gql`
				mutation ADD_LICENSE_REQUEST_FOR_ANDROID_SUBSCRIPTION($licenseRequest: LicenseRequestInput!) {
					AddLicenseRequestForAndroidSubscription(NewLicenseRequest: $licenseRequest) {
						${commonAddFields}
					}
				}
			`,
			variables: {
				licenseRequest: input
			}
		});

		return AddLicenseRequestForAndroidSubscription;
	} catch (e) {
		console.log(e.message);
	}
};

export const addLicenseRequest = async (input) => {
	const {
		data: { AddLicenseRequest }
	} = await apollo.mutate({
		mutation: gql`
			mutation ADD_LICENSE_REQUEST($licenseRequest: LicenseRequestInput!) {
				AddLicenseRequest(NewLicenseRequest: $licenseRequest) {
					${commonAddFields}
					${commonFields}
				}
			}
		`,
		variables: {
			licenseRequest: input
		}
	});

	return AddLicenseRequest;
};

export const getLicenseRequest = async (xid) => {
	try {
		const query = gql`
			query GET_LICENSE_REQUEST($xid: String!) {
				GetLicenceRequest(LicenseRequestXid: $xid) {
					${commonAddFields}
					${commonFields}
					id
					phone
					password
					validated
					validatedOn
					language
					userId
					timeZone
					requestType
					userTypeForFilm
					nextProductionDate
					budgetBracket
					emailValidationCode
					phoneValidationCode
					userTypeDesc
					languageDesc
				}
			}
		`;
		const {
			data: { GetLicenceRequest }
		} = await apollo.query({
			query,
			variables: {
				xid
			},
			fetchPolicy: 'no-cache'
		});

		return GetLicenceRequest;
	} catch (error) {
		console.log(error.message);
	}
};
