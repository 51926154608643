var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "animated fadeIn" },
            [
              _c("b-row", [
                _c("div", { staticClass: "w-100 p-1 container-layout" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-colmumn card w-100 py-4" },
                    [
                      _c(
                        "b-form",
                        { staticClass: "d-flex flex-column w-100 p-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "card-collapsible" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row cursor-pointer header-card-collapsible align-items-center responsive-mobile",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader(
                                        "personnal-data"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-mobile title w-100" },
                                    [
                                      _c("b-icon", {
                                        staticStyle: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                        },
                                        attrs: { icon: "person-check-fill" },
                                      }),
                                      _c("span", { staticClass: "ml-3" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                12654,
                                                "Personal data"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end w-100",
                                    },
                                    [
                                      !_vm.configAccordion["personnal-data"]
                                        .isVisible
                                        ? _c(_vm.getLucideIcon("ChevronDown"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.configAccordion["personnal-data"]
                                        .isVisible
                                        ? _c(_vm.getLucideIcon("ChevronUp"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configAccordion["personnal-data"]
                                        .isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-80 flex-column gap-1",
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          121,
                                                                          "Personal number"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "PersonalNumber"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .usePersonalNumber
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          122,
                                                                          "Email"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Email"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useEmail
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryEmail,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryEmail",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryEmail",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          123,
                                                                          "Phone"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Phone"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .usePhone
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .usePhone,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryPhone,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryPhone",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryPhone",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          124,
                                                                          "First Name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "FirstName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useFirstName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "custom-disabled-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        isDisable:
                                                                          "",
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryFirstName,
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            1115511,
                                                                            "Mandatory"
                                                                          ),
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          125,
                                                                          "Last Name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "LastName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useLastName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "custom-disabled-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        isDisable:
                                                                          "",
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryLastName,
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            1115511,
                                                                            "Mandatory"
                                                                          ),
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          189,
                                                                          "Maiden name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "MaidenName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useMaidenName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useMaidenName,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryMaidenName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryMaidenName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryMaidenName",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          169,
                                                                          "Color"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Color"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useColor
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useColor,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryColor,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryColor",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryColor",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          126,
                                                                          "Nationality"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Nationality"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useNationality
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useNationality,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryNationality,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryNationality",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryNationality",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          127,
                                                                          "Language"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Language"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useLanguage
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useLanguage,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryLanguage,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryLanguage",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryLanguage",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          131,
                                                                          "Gender"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Gender"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useGender
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useGender,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryGender,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryGender",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryGender",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          200,
                                                                          "Title"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Title"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useTitle
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useTitle,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryTitle,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryTitle",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryTitle",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          128,
                                                                          "Birthdate"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Birthdate"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useBirthdate
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useBirthdate,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryBirthdate,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryBirthdate",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryBirthdate",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          129,
                                                                          "Place of birth"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "PlaceOfBirth"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .usePlaceOfBirth
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .usePlaceOfBirth,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryPlaceOfBirth,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryPlaceOfBirth",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryPlaceOfBirth",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          130,
                                                                          "City of birth ZIP"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "CityOfBirthZip"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useCityOfBirthZip
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useCityOfBirthZip,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryCityOfBirthZip,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryCityOfBirthZip",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryCityOfBirthZip",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          201,
                                                                          "Country of birth"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "CountryOfBirth"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useCountryOfBirth
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useCountryOfBirth,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryCountryOfBirth,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryCountryOfBirth",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryCountryOfBirth",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap align-items-center w-20 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c("b-avatar", {
                                                                staticClass:
                                                                  "avatar",
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row justify-content-center",
                                                              staticStyle: {
                                                                gap: "5px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn bg-transparent border-0",
                                                                      attrs: {
                                                                        variant:
                                                                          "primary",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleUseChange(
                                                                              "ProfilePicture"
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .projectFieldOption
                                                                        .useProfilePicture
                                                                        ? _c(
                                                                            _vm.getLucideIcon(
                                                                              _vm
                                                                                .ICONS
                                                                                .EYE
                                                                                .name
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .EYE
                                                                                      .color,
                                                                                  size: 20,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            _vm.getLucideIcon(
                                                                              _vm
                                                                                .ICONS
                                                                                .EYE_OFF
                                                                                .name
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .EYE_OFF
                                                                                      .color,
                                                                                  size: 20,
                                                                                },
                                                                            }
                                                                          ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-items-center justify-content-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                !_vm
                                                                                  .projectFieldOption
                                                                                  .useProfilePicture,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .projectFieldOption
                                                                                  .mandatoryProfilePicture,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.projectFieldOption,
                                                                                    "mandatoryProfilePicture",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "projectFieldOption.mandatoryProfilePicture",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                "label-mobile",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    1115511,
                                                                                    "Mandatory"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-collapsible mt-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row cursor-pointer header-card-collapsible align-items-center responsive-mobile",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader(
                                        "identification"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-mobile title w-100" },
                                    [
                                      _c("b-icon", {
                                        staticStyle: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                        },
                                        attrs: { icon: "person-lines-fill" },
                                      }),
                                      _c("span", { staticClass: "ml-3" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(106, "Identification")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end w-100",
                                    },
                                    [
                                      !_vm.configAccordion["identification"]
                                        .isVisible
                                        ? _c(_vm.getLucideIcon("ChevronDown"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.configAccordion["identification"]
                                        .isVisible
                                        ? _c(_vm.getLucideIcon("ChevronUp"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configAccordion["identification"]
                                        .isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            256,
                                            "Identification Numbers"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          133,
                                                                          "National Registration Number"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "NationalRegistrationNumber"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useNationalRegistrationNumber
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useNationalRegistrationNumber,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryNationalRegistrationNumber,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryNationalRegistrationNumber",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryNationalRegistrationNumber",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          134,
                                                                          "Company Number"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "CompanyNumber"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useCompanyNumber
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useCompanyNumber,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryCompanyNumber,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryCompanyNumber",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryCompanyNumber",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          300,
                                                                          '"Congés spectacles" ID'
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "CongesSpectacleId"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useCongesSpectacleId
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useCongesSpectacleId,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryCongesSpectacleId,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryCongesSpectacleId",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryCongesSpectacleId",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          142,
                                                                          "Signature picture"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SignaturePicture"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSignaturePicture
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSignaturePicture,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySignaturePicture,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySignaturePicture",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySignaturePicture",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          135,
                                                                          "ID Name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "IdName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useIdName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useIdName,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryIdName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryIdName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryIdName",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          136,
                                                                          "ID Number"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "IdNumber"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useIdNumber
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useIdNumber,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryIdNumber,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryIdNumber",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryIdNumber",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          137,
                                                                          "ID picture - Front"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "IdPictureFront"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useIdPictureFront
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useIdPictureFront,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryIdPictureFront,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryIdPictureFront",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryIdPictureFront",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          138,
                                                                          "ID picture - Back"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "IdPictureBack"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useIdPictureBack
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useIdPictureBack,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryIdPictureBack,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryIdPictureBack",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryIdPictureBack",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          333,
                                                                          "Driver license"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "DriverLicense"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useDriverLicense
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useDriverLicense,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryDriverLicense,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryDriverLicense",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryDriverLicense",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          139,
                                                                          "Driver license number"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "DriverLicenseNumber"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useDriverLicenseNumber
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useDriverLicenseNumber,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryDriverLicenseNumber,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryDriverLicenseNumber",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryDriverLicenseNumber",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          140,
                                                                          "Driver license picture - Front"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "DriverLicensePictureFront"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useDriverLicensePictureFront
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useDriverLicensePictureFront,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryDriverLicensePictureFront,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryDriverLicensePictureFront",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryDriverLicensePictureFront",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          141,
                                                                          "Driver license picture - Back"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "DriverLicensePictureBack"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useDriverLicensePictureBack
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useDriverLicensePictureBack,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryDriverLicensePictureBack,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryDriverLicensePictureBack",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryDriverLicensePictureBack",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-collapsible mt-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader("finance")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "title w-90" },
                                    [
                                      _c(_vm.getLucideIcon("Wallet"), {
                                        tag: "component",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                          size: 22,
                                        },
                                      }),
                                      _c("span", { staticClass: "ml-3" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(1244, "Finance"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end w-10",
                                    },
                                    [
                                      !_vm.configAccordion["finance"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronDown"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.configAccordion["finance"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronUp"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configAccordion["finance"].isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            1235654,
                                            "Bank information"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          143,
                                                                          "IBAN"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Iban"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useIban
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useIban,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryIban,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryIban",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryIban",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          144,
                                                                          "BIC"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Bic"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useBic
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useBic,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryBic,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryBic",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryBic",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          3323,
                                                                          "Bank account owner name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "BankAccountOwnerName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useBankAccountOwnerName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useBankAccountOwnerName,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryBankAccountOwnerName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryBankAccountOwnerName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryBankAccountOwnerName",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          556,
                                                                          "Bank detail document"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "BankDetailDocument"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useBankDetailDocument
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useBankDetailDocument,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryBankDetailDocument,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryBankDetailDocument",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryBankDetailDocument",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            101,
                                            "Expense Reimbursement Bank Information"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          102,
                                                                          "IBAN"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SecondIban"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSecondIban
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSecondIban,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySecondIban,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySecondIban",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySecondIban",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          103,
                                                                          "BIC"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SecondBic"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSecondBic
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSecondBic,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySecondBic,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySecondBic",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySecondBic",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          104,
                                                                          "Bank account owner name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SecondBankAccountOwnerName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSecondBankAccountOwnerName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSecondBankAccountOwnerName,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySecondBankAccountOwnerName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySecondBankAccountOwnerName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySecondBankAccountOwnerName",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          105,
                                                                          "Bank detail document"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SecondBankDetailDocument"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSecondBankDetailDocument
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSecondBankDetailDocument,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySecondBankDetailDocument,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySecondBankDetailDocument",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySecondBankDetailDocument",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [_vm._v(_vm._s(_vm.FormMSG(164, "Taxes")))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          164,
                                                                          "Taxes"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Taxes"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useTaxes
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useTaxes,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryTaxes,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryTaxes",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryTaxes",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          334,
                                                                          "Tax Declaration Country"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "TaxDeclarationCountry"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useTaxDeclarationCountry
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useTaxDeclarationCountry,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryTaxDeclarationCountry,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryTaxDeclarationCountry",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryTaxDeclarationCountry",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-collapsible mt-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row cursor-pointer header-card-collapsible align-items-center responsive-mobile",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader("address")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-mobile title w-100" },
                                    [
                                      _c("b-icon", {
                                        staticStyle: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                        },
                                        attrs: { icon: "geo-fill" },
                                      }),
                                      _c("span", { staticClass: "ml-3" }, [
                                        _vm._v(" Address "),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end w-100",
                                    },
                                    [
                                      !_vm.configAccordion["address"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronDown"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.configAccordion["address"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronUp"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configAccordion["address"].isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          145,
                                                                          "Country"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Country"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useCountry
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useCountry,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryCountry,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryCountry",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryCountry",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          146,
                                                                          "Internet search"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "InternetResearch"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useInternetResearch
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          147,
                                                                          "Street"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Street"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useStreet
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useStreet,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryStreet,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryStreet",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryStreet",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          148,
                                                                          "Number"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Number"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useNumber
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useNumber,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryNumber,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryNumber",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryNumber",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          149,
                                                                          "Box"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Box"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useBox
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useBox,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryBox,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryBox",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryBox",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          150,
                                                                          "Zip Code"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "ZipCode"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useZipCode
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useZipCode,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryZipCode,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryZipCode",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryZipCode",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          151,
                                                                          "City"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "City"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useCity
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useCity,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryCity,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryCity",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryCity",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          152,
                                                                          "State/Region"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "StateRegion"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useStateRegion
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useStateRegion,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryStateRegion,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryStateRegion",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryStateRegion",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-collapsible mt-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader("health")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "title w-90" },
                                    [
                                      _c(_vm.getLucideIcon("HeartPulse"), {
                                        tag: "component",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                          size: 22,
                                        },
                                      }),
                                      _c("span", { staticClass: "ml-3" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(1252, "Health"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end w-10",
                                    },
                                    [
                                      !_vm.configAccordion["health"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronDown"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.configAccordion["health"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronUp"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configAccordion["health"].isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              132,
                                                              "Social Security Number"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "SocialSecurityNumber"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useSocialSecurityNumber
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useSocialSecurityNumber,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatorySocialSecurityNumber,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatorySocialSecurityNumber",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatorySocialSecurityNumber",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              600,
                                                              "Social security card"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "SocialSecurityCard"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useSocialSecurityCard
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useSocialSecurityCard,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatorySocialSecurityCard,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatorySocialSecurityCard",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatorySocialSecurityCard",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              601,
                                                              "Insurance certificate"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "InsuranceCertificate"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useInsuranceCertificate
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useInsuranceCertificate,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryInsuranceCertificate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryInsuranceCertificate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryInsuranceCertificate",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              605,
                                                              "Social security center"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "SocialSecurityCenter"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useSocialSecurityCenter
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useSocialSecurityCenter,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatorySocialSecurityCenter,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatorySocialSecurityCenter",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatorySocialSecurityCenter",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              606,
                                                              "Social security center address"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "SocialSecurityCenterAddress"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useSocialSecurityCenterAddress
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useSocialSecurityCenterAddress,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatorySocialSecurityCenterAddress,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatorySocialSecurityCenterAddress",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatorySocialSecurityCenterAddress",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              607,
                                                              "Last medical visit date"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "LastMedicalVisitDate"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useLastMedicalVisitDate
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useLastMedicalVisitDate,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryLastMedicalVisitDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryLastMedicalVisitDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryLastMedicalVisitDate",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              608,
                                                              "Medical visit validity"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "MedicalVisitValidity"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useMedicalVisitValidity
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useMedicalVisitValidity,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryMedicalVisitValidity,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryMedicalVisitValidity",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryMedicalVisitValidity",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              609,
                                                              "Medical visit document"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "MedicalVisitDocument"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useMedicalVisitDocument
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useMedicalVisitDocument,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryMedicalVisitDocument,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryMedicalVisitDocument",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryMedicalVisitDocument",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              166,
                                                              "Worker with a disability"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "WorkerWithADisability"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useWorkerWithADisability
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              710,
                                                              "Disability notification date"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "DisabilityNotificationDate"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useDisabilityNotificationDate
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useDisabilityNotificationDate,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryDisabilityNotificationDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryDisabilityNotificationDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryDisabilityNotificationDate",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              711,
                                                              "Type"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "Type"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useType
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useType,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryType",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              712,
                                                              "Status"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "Status"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useStatus
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useStatus,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryStatus",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryStatus",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              715,
                                                              "Invalidity"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "Invalidity"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useInvalidity
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useInvalidity,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryInvalidity,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryInvalidity",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryInvalidity",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              713,
                                                              "RQTH"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "Rqth"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useRqth
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          disabled:
                                                            !_vm
                                                              .projectFieldOption
                                                              .useRqth,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .projectFieldOption
                                                              .mandatoryRqth,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectFieldOption,
                                                              "mandatoryRqth",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectFieldOption.mandatoryRqth",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              714,
                                                              "Invalidity Percentage"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "InvalidityPercentage"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useInvalidityPercentage
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useInvalidityPercentage,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryInvalidityPercentage,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryInvalidityPercentage",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryInvalidityPercentage",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              167,
                                                              "Have Allergies?"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "HaveAllergies"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useHaveAllergies
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              168,
                                                              "Allergies details"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "AllergiesDetails"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useAllergiesDetails
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useAllergiesDetails,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryAllergiesDetails,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryAllergiesDetails",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryAllergiesDetails",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-collapsible mt-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row cursor-pointer header-card-collapsible align-items-center responsive-mobile",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader("family")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-mobile title w-100" },
                                    [
                                      _c("b-icon", {
                                        staticStyle: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                        },
                                        attrs: { icon: "people-fill" },
                                      }),
                                      _c("span", { staticClass: "ml-3" }, [
                                        _vm._v(" Family "),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end w-100",
                                    },
                                    [
                                      !_vm.configAccordion["family"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronDown"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.configAccordion["family"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronUp"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configAccordion["family"].isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [_vm._v("Family composition")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          153,
                                                                          "Civil status"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "CivilStatus"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useCivilStatus
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useCivilStatus,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryCivilStatus,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryCivilStatus",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryCivilStatus",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          154,
                                                                          "Spouse first name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SpouseFirstName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSpouseFirstName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSpouseFirstName,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySpouseFirstName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySpouseFirstName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySpouseFirstName",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          155,
                                                                          "Spouse last name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SpouseLastName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSpouseLastName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSpouseLastName,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySpouseLastName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySpouseLastName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySpouseLastName",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          156,
                                                                          "Spouse birthdate"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SpouseBirthdate"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSpouseBirthdate
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSpouseBirthdate,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySpouseBirthdate,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySpouseBirthdate",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySpouseBirthdate",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          157,
                                                                          "Spouse has salary?"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SpouseHasSalary"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSpouseHasSalary
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1570,
                                                                          "Spouse profession"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SpouseProfession"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSpouseProfession
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSpouseProfession,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySpouseProfession,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySpouseProfession",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySpouseProfession",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          158,
                                                                          "Number of dependent children"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "NumberOfDependentChildren"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useNumberOfDependentChildren
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useNumberOfDependentChildren,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryNumberOfDependentChildren,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryNumberOfDependentChildren",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryNumberOfDependentChildren",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          159,
                                                                          "Number of disabled children"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "NumberOfDisabledChildren"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useNumberOfDisabledChildren
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useNumberOfDisabledChildren,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryNumberOfDisabledChildren,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryNumberOfDisabledChildren",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryNumberOfDisabledChildren",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          160,
                                                                          "Number of dependent persons"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "NumberOfDependentPerson"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useNumberOfDependentPerson
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useNumberOfDependentPerson,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryNumberOfDependentPerson,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryNumberOfDependentPerson",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryNumberOfDependentPerson",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          161,
                                                                          "Number of disabled persons"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "NumberOfDisabledPerson"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useNumberOfDisabledPerson
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useNumberOfDisabledPerson,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryNumberOfDisabledPerson,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryNumberOfDisabledPerson",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryNumberOfDisabledPerson",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [_vm._v("Emergency contact")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          162,
                                                                          "Contact name"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "ContactName"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useContactName
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useContactName,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryContactName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryContactName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryContactName",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          163,
                                                                          "Contact phone number"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "ContactPhoneNumber"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useContactPhoneNumber
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useContactPhoneNumber,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryContactPhoneNumber,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryContactPhoneNumber",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryContactPhoneNumber",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-collapsible mt-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader(
                                        "personal-vehicle"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "title w-90" }, [
                                    _c("span", [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "1em",
                                            height: "1.15em",
                                            fill: "rgba(6, 38, 62, 0.84)",
                                            viewBox: "0 0 16 16",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _c("span", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1226, "Personal vehicle")
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end w-10",
                                    },
                                    [
                                      !_vm.configAccordion["personal-vehicle"]
                                        .isVisible
                                        ? _c(_vm.getLucideIcon("ChevronDown"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.configAccordion["personal-vehicle"]
                                        .isVisible
                                        ? _c(_vm.getLucideIcon("ChevronUp"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configAccordion["personal-vehicle"]
                                        .isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              706,
                                                              "Name"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "VehicleName"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useVehicleName
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useVehicleName,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryVehicleName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryVehicleName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryVehicleName",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              7806,
                                                              "Brand"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "VehicleBrand"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useVehicleBrand
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useVehicleBrand,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryVehicleBrand,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryVehicleBrand",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryVehicleBrand",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              705,
                                                              "Model"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "VehicleModel"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useVehicleModel
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useVehicleModel,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryVehicleModel,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryVehicleModel",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryVehicleModel",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              704,
                                                              "Number plate"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "VehiclePlateNumber"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useVehiclePlateNumber
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useVehiclePlateNumber,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryVehiclePlateNumber,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryVehiclePlateNumber",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryVehiclePlateNumber",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              703,
                                                              "Transportation mean"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "VehicleTransportationMean"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useVehicleTransportationMean
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useVehicleTransportationMean,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryVehicleTransportationMean,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryVehicleTransportationMean",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryVehicleTransportationMean",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              702,
                                                              "Transportation mean detail"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        disabled: "",
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "VehicleTransportationMeanDetail"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useVehicleTransportationMeanDetail
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryVehicleTransportationMeanDetail,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryVehicleTransportationMeanDetail",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryVehicleTransportationMeanDetail",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              700,
                                                              "Kg CO2 per Km"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "VehicleKgCoTwo"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useVehicleKgCoTwo
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useVehicleKgCoTwo,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryVehicleKgCoTwo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryVehicleKgCoTwo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryVehicleKgCoTwo",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              707,
                                                              "Power"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "Power"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .usePower
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .usePower,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryPower,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryPower",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryPower",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              708,
                                                              "Gray card front picture"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "GrayCardFrontPicture"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useGrayCardFrontPicture
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useGrayCardFrontPicture,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryGrayCardFrontPicture,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryGrayCardFrontPicture",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryGrayCardFrontPicture",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-start p-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-mobile",
                                                      attrs: {
                                                        for: "reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              709,
                                                              "Gray card back picture"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUseChange(
                                                            "GrayCardBackPicture"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.projectFieldOption
                                                        .useGrayCardBackPicture
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.EYE
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE_OFF
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .EYE_OFF
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .projectFieldOption
                                                                .useGrayCardBackPicture,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .projectFieldOption
                                                                .mandatoryGrayCardBackPicture,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectFieldOption,
                                                                "mandatoryGrayCardBackPicture",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectFieldOption.mandatoryGrayCardBackPicture",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "label-mobile",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1115511,
                                                                    "Mandatory"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-collapsible mt-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row align-items-center cursor-pointer header-card-collapsible responsive-mobile",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader("other")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-mobile title w-100" },
                                    [
                                      _c("b-icon", {
                                        staticStyle: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                        },
                                        attrs: { icon: "list" },
                                      }),
                                      _c("span", { staticClass: "ml-3" }, [
                                        _vm._v(" Other "),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end w-100",
                                    },
                                    [
                                      !_vm.configAccordion["other"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronDown"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.configAccordion["other"].isVisible
                                        ? _c(_vm.getLucideIcon("ChevronUp"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configAccordion["other"].isVisible,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(15688, "Privacy options")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "showMyEmailInCrewList"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .showMyEmailInCrewList
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-items-center justify-content-start",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "text-mobile pt-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            168484,
                                                                            "Show my email in crew list"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "showMyPhoneInCrewList"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .showMyPhoneInCrewList
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-items-center justify-content-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "text-mobile pt-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            165,
                                                                            "Show my phone in crew list"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(599, "Loyalty cards")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          520,
                                                                          "SNCF loyalty card"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SncfLoyaltyCard"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSncfLoyaltyCard
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSncfLoyaltyCard,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySncfLoyaltyCard,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySncfLoyaltyCard",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySncfLoyaltyCard",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          521,
                                                                          "SNCF discount card"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "SncfDiscountCard"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSncfDiscountCard
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSncfDiscountCard,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySncfDiscountCard,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySncfDiscountCard",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySncfDiscountCard",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          522,
                                                                          "Flying blue card "
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "FlyingBlueCard"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useFlyingBlueCard
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useFlyingBlueCard,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryFlyingBlueCard,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryFlyingBlueCard",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryFlyingBlueCard",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          523,
                                                                          "Other airline card"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "OtherAirlineCard"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useOtherAirlineCard
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useOtherAirlineCard,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryOtherAirlineCard,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryOtherAirlineCard",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryOtherAirlineCard",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(530, "Foreign worker")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          531,
                                                                          "Residence permit number"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "ResidencePermitNumber"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useResidencePermitNumber
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useResidencePermitNumber,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryResidencePermitNumber,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryResidencePermitNumber",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryResidencePermitNumber",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          532,
                                                                          "Residence permit validity end"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "ResidenceValidityEnd"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useResidenceValidityEnd
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useResidenceValidityEnd,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryResidenceValidityEnd,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryResidenceValidityEnd",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryResidenceValidityEnd",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          533,
                                                                          "Resident permit"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "ResidencePermit"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useResidencePermit
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useResidencePermit,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryResidencePermit,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryResidencePermit",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryResidencePermit",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          534,
                                                                          "Work card"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "WorkCard"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useWorkCard
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useWorkCard,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryWorkCard,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryWorkCard",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryWorkCard",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(598, "Miscellanous"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          535,
                                                                          "Pseudonym"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Pseudonym"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .usePseudonym
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .usePseudonym,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryPseudonym,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryPseudonym",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryPseudonym",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 5 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          536,
                                                                          "Generic mention professional association"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "GenericMentionProfessionalAssociation"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useGenericMentionProfessionalAssociation
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useGenericMentionProfessionalAssociation,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryGenericMentionProfessionalAssociation,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryGenericMentionProfessionalAssociation",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryGenericMentionProfessionalAssociation",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(900, "Retirement"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          901,
                                                                          "Retired ?"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Retired"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useRetired
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [_vm._v(_vm._s(_vm.FormMSG(905, "Study")))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          170,
                                                                          "Study level"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "StudyLevel"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useStudyLevel
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useStudyLevel,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryStudyLevel,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryStudyLevel",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryStudyLevel",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-mobile-sub-title sub-title",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(907, "Clothes"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-responsive" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap w-100 flex-column gap-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          171,
                                                                          "Size"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "Size"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useSize
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useSize,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatorySize,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatorySize",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatorySize",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          172,
                                                                          "Pant size"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "PantSize"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .usePantSize
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .usePantSize,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryPantSize,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryPantSize",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryPantSize",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          173,
                                                                          "Jacket size"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "JacketSize"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useJacketSize
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useJacketSize,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryJacketSize,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryJacketSize",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryJacketSize",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row flex-wrap gap-15",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          174,
                                                                          "Shoe size"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "ShoeSize"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useShoeSize
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useShoeSize,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryShoeSize,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryShoeSize",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryShoeSize",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      attrs: { cols: 3 },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-items-start p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "text-mobile",
                                                                  attrs: {
                                                                    for: "reference",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          175,
                                                                          "T-shirt size"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUseChange(
                                                                          "TshirtSize"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .projectFieldOption
                                                                    .useTshirtSize
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EYE_OFF
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EYE_OFF
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm
                                                                            .projectFieldOption
                                                                            .useTshirtSize,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .projectFieldOption
                                                                            .mandatoryTshirtSize,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.projectFieldOption,
                                                                              "mandatoryTshirtSize",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "projectFieldOption.mandatoryTshirtSize",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "label-mobile",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1115511,
                                                                                "Mandatory"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "divider-mobile",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 pd-1",
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-1",
                                  staticStyle: {
                                    "font-size": "1.25rem",
                                    "letter-spacing": "0.05rem",
                                  },
                                  attrs: { type: "submit", variant: "primary" },
                                  on: { click: _vm.submitForm },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(54213, "Save changes")
                                      ) +
                                      "\n\t\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "b-modal",
                {
                  staticClass: "modal-success",
                  attrs: {
                    "header-class": "header-class-modal-doc-package",
                    title: "Success",
                    "ok-variant": "success",
                    "ok-only": "",
                    "cancel-title": "close",
                  },
                  on: {
                    ok: function ($event) {
                      _vm.successModal = false
                    },
                  },
                  model: {
                    value: _vm.successModal,
                    callback: function ($$v) {
                      _vm.successModal = $$v
                    },
                    expression: "successModal",
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(1302214, "Your information has been saved.")
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }