var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAllDisableComponent
    ? _c("div", { staticClass: "d-flex flex-column align-items-start p-2" }, [
        _c("div", { staticClass: "d-flex flex-row align-items-start" }, [
          _c(
            "label",
            {
              staticClass: "text-mobile",
              attrs: { disabled: _vm.isAllDisableComponent, for: "reference" },
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "d-flex flex-row justify-content-center",
            staticStyle: { gap: "5px" },
          },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn bg-transparent border-0",
                    attrs: {
                      disabled: _vm.isAllDisableComponent,
                      variant: "primary",
                      size: "sm",
                    },
                  },
                  [
                    _c(_vm.getLucideIcon(_vm.ICONS.EYE_OFF.name), {
                      tag: "component",
                      attrs: { color: _vm.ICONS.EYE.color, size: 20 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center",
                  },
                  [
                    _c("b-form-checkbox", {
                      attrs: {
                        size: "lg",
                        disabled: _vm.isAllDisableComponent,
                      },
                      model: {
                        value: _vm.value.isMandatory,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "isMandatory", $$v)
                        },
                        expression: "value.isMandatory",
                      },
                    }),
                    _c("label", { staticClass: "label-mobile-lg" }, [
                      _vm._v("Mandatory"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ])
    : _c("div", { staticClass: "d-flex flex-column align-items-start p-2" }, [
        !_vm.isShowPicture && !_vm.isNotShowCheck
          ? _c("div", { staticClass: "d-flex flex-row align-items-start" }, [
              _c(
                "label",
                { staticClass: "text-mobile", attrs: { for: "reference" } },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
            ])
          : _vm._e(),
        _vm.isShowPicture && !_vm.isNotShowCheck
          ? _c("div", [_c("b-avatar", { staticClass: "avatar" })], 1)
          : _vm._e(),
        _vm.isRequiredOption
          ? _c(
              "div",
              {
                staticClass: "d-flex flex-row justify-content-center",
                staticStyle: { gap: "5px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn bg-transparent border-0",
                        attrs: {
                          disabled: _vm.isRequiredOption,
                          variant: "primary",
                          size: "sm",
                        },
                      },
                      [
                        _vm.value.isActive
                          ? _c(_vm.getLucideIcon(_vm.ICONS.EYE.name), {
                              tag: "component",
                              attrs: { color: _vm.ICONS.EYE.color, size: 20 },
                            })
                          : _c(_vm.getLucideIcon(_vm.ICONS.EYE_OFF.name), {
                              tag: "component",
                              attrs: {
                                color: _vm.ICONS.EYE_OFF.color,
                                size: 20,
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isShowMandatoryText
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { disabled: _vm.isRequiredOption },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkActionIsMandatory(
                                      _vm.value.isMandatory
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.value.isMandatory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "isMandatory", $$v)
                                  },
                                  expression: "value.isMandatory",
                                },
                              },
                              [
                                _c("label", { staticClass: "label-mobile" }, [
                                  _vm._v("Mandatory"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _c(
              "div",
              {
                class: `${
                  _vm.isShowPicture
                    ? "d-flex flex-row justify-content-center w-100"
                    : "d-flex flex-row justify-content-center"
                }`,
                staticStyle: { gap: "5px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn bg-transparent border-0",
                        attrs: { variant: "primary", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.checkActionIsActive(_vm.value.isActive)
                          },
                        },
                      },
                      [
                        _vm.value.isActive
                          ? _c(_vm.getLucideIcon(_vm.ICONS.EYE.name), {
                              tag: "component",
                              attrs: { color: _vm.ICONS.EYE.color, size: 20 },
                            })
                          : _c(_vm.getLucideIcon(_vm.ICONS.EYE_OFF.name), {
                              tag: "component",
                              attrs: {
                                color: _vm.ICONS.EYE_OFF.color,
                                size: 20,
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isShowMandatoryText
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.checkActionIsMandatory(
                                      _vm.value.isMandatory
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.value.isMandatory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "isMandatory", $$v)
                                  },
                                  expression: "value.isMandatory",
                                },
                              },
                              [
                                _c("label", { staticClass: "label-mobile" }, [
                                  _vm._v("Mandatory"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center",
                  },
                  [
                    _vm.isNotShowCheck
                      ? _c("div", [
                          _c("label", { staticClass: "text-mobile" }, [
                            _vm._v(_vm._s(_vm.title)),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }