<template>
	<b-row class="animated fadeIn" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<b-col sm="3">
			<b-form-group :label="FormMSG(1, 'Name')">
				<b-form-input
					type="text"
					v-model="company.name"
					autocomplete="off"
					:class="{
						'is-invalid': $v.company.name.$error
					}"
					@keyup="handleInputChanged('name')"
				/>
			</b-form-group>
		</b-col>
		<b-col sm="6">
			<b-row>
				<b-col sm="12">
					<b-form-group :label="FormMSG(2, 'Address')">
						<v-address
							:params="{ title: FormMSG(3, 'Address') }"
							:address-id="company.addressId"
							:is-submitted="true"
							:error="$v.company.addressId.$error"
							@change="handleDynamicAddressChange"
							@delete="handleDynamicAddressDelete"
							@address-maplink-change="handleAddressMapLinkChange"
						/>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="5">
					<b-form-group>
						<template slot="label">
							<label for="bic">{{ this.FormMSG(30, 'Company Registration Number') }}</label>
						</template>
						<b-form-input
							v-model="company.companyRegistrationNumber"
							type="text"
							:placeholder="this.FormMSG(31, 'Enter your Company Registration Number')"
							@keyup="handleInputChanged('companyRegistrationNumber')"
						></b-form-input>
					</b-form-group>
				</b-col>
				<b-col sm="7">
					<b-form-group>
						<template slot="label">
							<label for="bic">{{ this.FormMSG(32, 'Tax Registration Number') }}</label>
						</template>
						<b-form-input
							v-model="company.taxRegistrationNumber"
							type="text"
							:placeholder="this.FormMSG(33, 'Enter your Tax Registration Number')"
							@keyup="handleInputChanged('taxRegistrationNumber')"
						></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="5">
					<b-form-group>
						<template slot="label">
							<label for="bic">{{ this.FormMSG(34, 'General Phone') }}</label>
						</template>
						<b-form-input
							v-model="company.phone"
							type="text"
							:placeholder="this.FormMSG(35, 'Enter your General Phone')"
							@keyup="handleInputChanged('phone')"
						></b-form-input>
					</b-form-group>
				</b-col>
				<b-col sm="7">
					<b-form-group>
						<template slot="label">
							<label for="bic">{{ this.FormMSG(36, 'General Email') }}</label>
						</template>
						<b-form-input
							v-model="company.email"
							type="text"
							:placeholder="this.FormMSG(37, 'Enter your General Email')"
							@keyup="handleInputChanged('email')"
						></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="5">
					<b-form-group>
						<template slot="label">
							<label for="bic">{{ this.FormMSG(38, 'Accounts phone') }}</label>
						</template>
						<b-form-input
							v-model="company.accountPhone"
							type="text"
							:placeholder="this.FormMSG(39, 'Enter your Accounts phone')"
							@keyup="handleInputChanged('accountPhone')"
						></b-form-input>
					</b-form-group>
				</b-col>
				<b-col sm="7">
					<b-form-group>
						<template slot="label">
							<label for="bic">{{ this.FormMSG(40, 'Accounts email') }}</label>
						</template>
						<b-form-input
							v-model="company.accountEmail"
							type="text"
							:placeholder="this.FormMSG(41, 'Enter your Accounts email')"
							@keyup="handleInputChanged('accountEmail')"
						></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="5">
					<b-form-group>
						<template slot="label">
							<label for="bic">{{ this.FormMSG(42, 'Po starting number') }}</label>
						</template>
						<b-form-input
							v-model="company.lastPoNumber"
							type="text"
							:placeholder="this.FormMSG(43, 'Enter Po starting number')"
							@keyup="handleInputChanged('lastPoNumber')"
						></b-form-input>
					</b-form-group>
				</b-col>
				<b-col sm="7"></b-col>
			</b-row>
		</b-col>
		<b-col sm="3"></b-col>
	</b-row>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength, decimal } from 'vuelidate/lib/validators';
import { greaterThanZero } from '@/shared/utils';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import _ from 'lodash';
import { INPUT_TYPE } from '@/shared/constants';

export default {
	name: 'FinanceCompanyForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		'v-address': MainAddressLocation
	},
	props: {
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		index: {
			type: Number,
			required: false,
			default: 0
		}
	},
	data() {
		return {
			company: null
		};
	},
	methods: {
		handleDynamicAddressChange(payload) {
			this.company.addressId = +payload.id;

			this.$emit('finance-company-form:updated', {
				index: this.index,
				item: this.company,
				isLineValid: !this.$v.$invalid
			});
		},
		handleDynamicAddressDelete() {
			this.company.addressId = 0;
		},
		handleAddressMapLinkChange(payload) {
			// console.log({ mapAddress: payload });
		},
		handleInputChanged(fieldName, type = 0) {
			if (type === INPUT_TYPE.INTEGER) {
				this.company[fieldName] = +this.company[fieldName];
			} else if (type === INPUT_TYPE.FLOAT) {
				this.company[fieldName] = parseFloat(this.company[fieldName]);
			}

			this.$emit('finance-company-form:updated', {
				index: this.index,
				item: this.company,
				isLineValid: !this.$v.$invalid
			});
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.company = _.cloneDeep(value);
					this.$v.$touch();
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			company: {
				name: {
					required,
					min: minLength(3)
				},
				addressId: {
					required,
					decimal,
					min: greaterThanZero
				}
			}
		};
	}
};
</script>
<style lang="scss" scoped></style>
