var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "animated fadeIn",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside my-0 py-0 pb-0"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(1, "General")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      { class: `${_vm.$screen.width <= 576 ? "" : "py-2"}` },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(4, "Category") } },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                  model: {
                                    value: _vm.flag.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.flag, "name", $$v)
                                    },
                                    expression: "flag.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            {
              class: `${_vm.$screen.width <= 992 ? "mt-2 pb-4" : "mt-2 pb-2"}`,
            },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside my-0 py-0 pb-0"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.forAllowances
                                ? _vm.FormMSG(3100, "Allowances")
                                : _vm.FormMSG(300, "Per Diem")
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-col",
                          [
                            _vm.$screen.width < 992 &&
                            _vm.flag.projectFlagItem.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-center pb-3 mb-2",
                                    staticStyle: { "font-size": "1rem" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.forAllowances
                                            ? _vm.FormMSG(
                                                1454,
                                                "Please, add a new custom allowances"
                                              )
                                            : _vm.FormMSG(
                                                140,
                                                "Please, add a new custom per diem"
                                              )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$screen.width >= 992 &&
                            _vm.flag.projectFlagItem.length > 0
                              ? _c(
                                  "b-table-simple",
                                  { attrs: { small: "", borderless: true } },
                                  [
                                    _c(
                                      "b-thead",
                                      {
                                        staticStyle: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-tr",
                                          {
                                            staticStyle: {
                                              "line-height": "8px",
                                            },
                                          },
                                          [
                                            _vm.action === "UPDATE" &&
                                            _vm.flag.projectFlagItem.length > 0
                                              ? _c("b-th")
                                              : _vm._e(),
                                            _c(
                                              "b-th",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                              },
                                              [_vm._v("#")]
                                            ),
                                            _c("b-th", [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(9, "Name"))
                                              ),
                                            ]),
                                            !_vm.forAllowances
                                              ? _c("b-th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        10,
                                                        "Abbreviation"
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _c("b-th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(100, "Amount")
                                                )
                                              ),
                                            ]),
                                            _vm.forAllowances
                                              ? _c("b-th")
                                              : _vm._e(),
                                            _c("b-th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(101, "Category")
                                                )
                                              ),
                                            ]),
                                            _c("b-th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(102, "Pay code")
                                                )
                                              ),
                                            ]),
                                            !_vm.forAllowances
                                              ? _c("b-th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(103, "Color")
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            !_vm.forAllowances
                                              ? _c("b-th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        104,
                                                        "Bookmark"
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _c("b-th"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-tbody",
                                      { staticClass: "text-center" },
                                      _vm._l(
                                        _vm.flag.projectFlagItem,
                                        function (item, i) {
                                          return _c(
                                            "finance-table-custom-item-form",
                                            {
                                              key: i,
                                              ref: _vm.generateTextId(
                                                `item-form-${i}`
                                              ),
                                              refInFor: true,
                                              attrs: {
                                                index: i,
                                                item: item,
                                                "for-allowances":
                                                  _vm.forAllowances,
                                                existDataAlreadySubmitted:
                                                  _vm.existDataAlreadySubmitted,
                                              },
                                              on: {
                                                "finance-table-flag-item-form:updated":
                                                  _vm.onLineTableChanged,
                                                "finance-table-flag-item-form:removed":
                                                  _vm.onLineTableRemoved,
                                              },
                                            }
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$screen.width >= 992 &&
                            _vm.flag.projectFlagItem.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-center pb-3",
                                    staticStyle: { "font-size": "1rem" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.forAllowances
                                            ? _vm.FormMSG(
                                                1454,
                                                "Please, add a new custom allowances"
                                              )
                                            : _vm.FormMSG(
                                                140,
                                                "Please, add a new custom per diem"
                                              )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("b-row", { staticClass: "mb-2 pb-3" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-end",
                          staticStyle: {
                            width: "93.5%",
                            margin: "-10px 0 0 0",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                              attrs: { size: "sm", variant: "outline-primary" },
                              on: { click: _vm.addNewFlag },
                            },
                            [
                              _c(_vm.getLucideIcon("PlusCircle"), {
                                tag: "component",
                                attrs: { size: 16, "stroke-width": 2 },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "px-2",
                                  staticStyle: { "margin-bottom": "-2px" },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.forAllowances
                                          ? _vm.FormMSG(
                                              1263,
                                              "Add new custom allowances"
                                            )
                                          : _vm.FormMSG(
                                              110,
                                              "Add new custom per diem"
                                            )
                                      ) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center mt-2",
                  style: `display: ${
                    _vm.displayAddBtn ? "block" : "none"
                  } !important;`,
                  attrs: {
                    sm: "4",
                    md: "4",
                    lg: "4",
                    xl: "4",
                    "offset-sm": "5",
                    "offset-md": "5",
                    "offset-lg": "5",
                    "offset-xl": "5",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      ref: "submit",
                      staticClass: "w-138-px",
                      attrs: {
                        type: "submit",
                        size: "md",
                        variant: "outline-primary",
                        block: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(13, "Save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-warning",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            "header-bg-variant": "warning",
            title: this.FormMSG(18, "Warning"),
            "ok-variant": "warning",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.warningMissingFlagItemModal = false
            },
          },
          model: {
            value: _vm.warningMissingFlagItemModal,
            callback: function ($$v) {
              _vm.warningMissingFlagItemModal = $$v
            },
            expression: "warningMissingFlagItemModal",
          },
        },
        [
          _c("div", { staticStyle: { "font-size": "1rem" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.FormMSG(
                    19,
                    "Please fill the field(s) required (coloring red) in flags section or remove one or all of them."
                  )
                ) +
                "\n\t\t"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }