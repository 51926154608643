var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "px-0",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _c(
        "div",
        { class: `${_vm.$screen.width <= 576 ? "my-project" : ""}` },
        [
          _vm.$screen.width <= 576
            ? _c("div", { staticClass: "title" }, [
                _c(
                  "h1",
                  {
                    staticClass: "main-page-title",
                    class: { "is-pwa": _vm.$isPwa() },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(10, "Settings")) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "b-col",
            { staticClass: "w-100 px-0", attrs: { cols: "12" } },
            [
              _c(
                "b-tabs",
                {
                  attrs: {
                    cards: "",
                    "active-nav-item-class": "font-weight-bold",
                    "active-tab-class": "font-weight-bold",
                  },
                  on: { "activate-tab": (n, p, e) => _vm.onTabClicked(n) },
                  model: {
                    value: _vm.tabs.activeTabs,
                    callback: function ($$v) {
                      _vm.$set(_vm.tabs, "activeTabs", $$v)
                    },
                    expression: "tabs.activeTabs",
                  },
                },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.getTitle } },
                    [_c("ProjectForm")],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        lazy: "",
                        title: _vm.FormMSG(22, "Departments and functions"),
                      },
                    },
                    [_c("department")],
                    1
                  ),
                  _vm.useFinance
                    ? _c(
                        "b-tab",
                        { attrs: { lazy: "", title: _vm.labelCategorie } },
                        [_c("NewCategory")],
                        1
                      )
                    : _vm._e(),
                  !_vm.isFilmSingle && !_vm.isFilmSingleFree && _vm.useFinance
                    ? _c(
                        "b-tab",
                        {
                          attrs: { lazy: "", title: _vm.getMemoDealTitle },
                          on: { click: _vm.refreshData },
                        },
                        [
                          _c("memo-deals", {
                            attrs: { "show-pay-code": _vm.showPayCode },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-tab",
                    {
                      ref: "crewMemberInfoRef",
                      attrs: {
                        lazy: "",
                        title: _vm.FormMSG(126545, "User profile setting"),
                      },
                    },
                    [_c("crew-member-info")],
                    1
                  ),
                  !_vm.isFilmSingle && !_vm.isFilmSingleFree && _vm.useLocations
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            lazy: "",
                            title: _vm.FormMSG(30, "Premises"),
                          },
                        },
                        [_c("Premises")],
                        1
                      )
                    : _vm._e(),
                  !_vm.isFilmSingle && !_vm.isFilmSingleFree && _vm.useFinance
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            lazy: "",
                            title: _vm.FormMSG(26, "Finance"),
                          },
                        },
                        [_c("Finance")],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }