var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.flag
    ? _c(
        "b-tr",
        { staticClass: "animated fadeIn" },
        [
          _vm.flag.id > 0
            ? _c(
                "b-td",
                { staticStyle: { "vertical-align": "middle", width: "2%" } },
                [
                  _c("b-form-checkbox", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left",
                        modifiers: { hover: true, left: true },
                      },
                    ],
                    attrs: { title: _vm.checkboxTitle, size: "lg" },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckboxChanged($event, "disabled")
                      },
                    },
                    model: {
                      value: _vm.flag.disabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.flag, "disabled", $$v)
                      },
                      expression: "flag.disabled",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.flag.id === 0 && _vm.existDataAlreadySubmitted
            ? _c("b-td")
            : _vm._e(),
          _c(
            "b-td",
            { style: `width: ${_vm.flag.id > 0 ? "68" : "70"}%` },
            [
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.flag.name.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("name")
                  },
                },
                model: {
                  value: _vm.flag.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.flag, "name", $$v)
                  },
                  expression: "flag.name",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            { staticStyle: { width: "28%" } },
            [
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.flag.short.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("short")
                  },
                },
                model: {
                  value: _vm.flag.short,
                  callback: function ($$v) {
                    _vm.$set(_vm.flag, "short", $$v)
                  },
                  expression: "flag.short",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            { staticStyle: { "vertical-align": "middle", width: "2%" } },
            [
              _c(_vm.getLucideIcon("MinusCircle"), {
                tag: "component",
                staticClass: "cursor-pointer mr-1",
                attrs: { color: "#EA4E2C", size: 18, "stroke-width": 3 },
                on: { click: _vm.removeItem },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }